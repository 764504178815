import { FaceSmileIcon, FaceFrownIcon, QuestionMarkCircleIcon, NoSymbolIcon } from '@heroicons/react/24/outline';

export const getLabelNav = (jobId, label) => {
  const currentLabel = label || 'review';
  return [
    {
      name: 'Review',
      to: `/job/${jobId}/labels/1/review`,
      icon: QuestionMarkCircleIcon,
      css: 'text-yellow-500',
      current: currentLabel === 'review',
    },
    {
      name: 'Good',
      to: `/job/${jobId}/labels/1/good`,
      icon: FaceSmileIcon,
      css: 'text-green-500',
      current: currentLabel === 'good',
    },

    {
      name: 'Bad',
      to: `/job/${jobId}/labels/1/bad`,
      icon: FaceFrownIcon,
      css: 'text-red-500',
      current: currentLabel === 'bad',
    },
    {
      name: 'Ignore',
      to: `/job/${jobId}/labels/1/ignore`,
      icon: NoSymbolIcon,
      css: 'text-indigo-500',
      current: currentLabel === 'ignore',
    },
  ];
};

export default getLabelNav;
