import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'utils/classNames';

export const Tabs = props => {
  const { nav } = props;

  return (
    <div className="h-16 border-b border-gray-200">
      <nav className="-mb-px flex h-full " aria-label="Tabs">
        {nav.map(tab => (
          <Link
            key={tab.name}
            to={tab.to}
            className={classNames(
              tab.current
                ? 'sm:bg-gray-200 sm:bg-opacity-50 border-gray-300'
                : 'border-transparent hover:sm:bg-gray-100 hover:sm:bg-opacity-50 hover:text-gray-700 hover:border-gray-300',
              'group inline-flex justify-center items-center w-full py-4 px-1 border-b-2 font-medium text-sm'
            )}
            aria-current={tab.current ? 'page' : undefined}
          >
            <tab.icon
              className={classNames(tab.css ? tab.css : 'text-gray-400', '-ml-0.5 mr-2 h-5 w-5')}
              aria-hidden="true"
            />
            <span className="whitespace-nowrap">{tab.name}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default Tabs;
