import { WrenchScrewdriverIcon, CubeIcon, RectangleGroupIcon, TagIcon } from '@heroicons/react/24/outline';
export const getJobSettingsNav = (jobId, section, job = {}) => {
  const currentSection = section || 'settings';
  const { device = {} } = job;
  const { cameraIntrinsics } = device;
  const nav = [];

  nav.push({
    name: 'Settings',
    to: `/job/${jobId}/settings`,
    icon: WrenchScrewdriverIcon,
    current: currentSection === 'settings',
  });
  nav.push({
    name: 'Labels',
    to: `/job/${jobId}/settings-labels`,
    icon: TagIcon,
    current: currentSection === 'settings-labels',
  });
  if (cameraIntrinsics) {
    nav.push({
      name: 'Sizes',
      to: `/job/${jobId}/settings-sizes`,
      icon: CubeIcon,
      current: currentSection === 'settings-sizes',
    });
  }
  nav.push({
    name: 'ROIs',
    to: `/job/${jobId}/settings-rois`,
    icon: RectangleGroupIcon,
    current: currentSection === 'settings-rois',
  });
  return nav;
};

export default getJobSettingsNav;
