import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import getAccountNav from '../navs/accountNav';

import { changePassword } from 'state/slices/authSlice';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = 'Required';
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  }

  if (!values.confirm_password) {
    errors.confirm_password = 'Required';
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = 'Passwords do not match';
  }

  return errors;
};

export const ChangePassword = () => {
  const { message, method, status } = useSelector(state => state.status);
  const dispatch = useDispatch();
  const sectionNav = getAccountNav('change-password');
  const { user } = useSelector(state => state.auth);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user.email,
      password: '',
      newPassword: '',
      confirm_password: '',
    },
    validate,
    onSubmit: values => {
      dispatch(changePassword(values));
    },
  });

  const handleSubmit = e => {
    formik.handleSubmit();
    e.preventDefault();
  };

  return (
    <PrivateTemplate headerTitle="Change Password" sectionTitle="Manage Account" sectionNav={sectionNav}>
      <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8">
        {method === 'changePassword' && status === 'rejected' && <WarningBanner text={message} />}
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="space-y-6 sm:space-y-4">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Password*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.password && formik.touched.password && <Error>{formik.errors.password}</Error>}
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      placeholder="Old password"
                      autoComplete="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    New Password*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.newPassword && formik.touched.newPassword && (
                      <Error>{formik.errors.newPassword}</Error>
                    )}

                    <input
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      placeholder="New password"
                      autoComplete="new-password"
                      onChange={formik.handleChange}
                      value={formik.values.newPassword}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="address_1" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Confirm New Password*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.confirm_password && formik.touched.confirm_password && (
                      <Error>{formik.errors.confirm_password}</Error>
                    )}

                    <input
                      id="confirm_password"
                      name="confirm_password"
                      type="password"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Confirm password"
                      autoComplete="new-password"
                      onChange={formik.handleChange}
                      value={formik.values.confirm_password}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input id="email" name="email" type="hidden" autoComplete="email" value={formik.values.email} />
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </PrivateTemplate>
  );
};

export default ChangePassword;
