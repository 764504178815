import { Chart } from 'react-chartjs-2';
import { color } from 'chart.js/helpers';

const MatrixChart = ({ data, label, labels }) => {
  const values = data.map(a => a.value);
  const vMin = Math.min(...values);
  const vMax = Math.max(...values);

  return (
    <Chart
      type="matrix"
      width={64}
      height={64}
      data={{
        datasets: [
          {
            label,
            data,
            backgroundColor(context) {
              const value = context.dataset.data[context.dataIndex].value;
              const alpha = (value - vMin) / (vMax - vMin);
              return color('#1D4ED8').alpha(alpha).rgbString();
            },
            borderColor: 'rgb(209,213,219)',
            borderWidth: 1,
            width: ({ chart }) => (chart.chartArea || {}).width / 2,
            height: ({ chart }) => (chart.chartArea || {}).height / 2,
          },
        ],
      }}
      options={{
        plugins: {
          colorschemes: {
            scheme: 'brewer.Paired12',
          },
          legend: false,
          tooltip: {
            callbacks: {
              title() {
                return '';
              },
              label(context) {
                const data = context.dataset.data[context.dataIndex];
                return [`${data.key}: ${data.value}`];
              },
            },
          },
        },
        scales: {
          x: {
            type: 'category',
            position: 'top',
            labels,
            ticks: {
              display: true,
            },
            grid: {
              display: false,
            },
          },
          y: {
            type: 'category',
            labels,
            reverse: false,
            offset: true,
            ticks: {
              display: true,
            },
            grid: {
              display: false,
            },
          },
        },
      }}
    />
  );
};

export default MatrixChart;
