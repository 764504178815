import { EnvelopeIcon } from '@heroicons/react/24/outline';
import PrivateTemplate from '../components/PrivateTemplate';
import EmailLink from '../components/EmailLink';

export const Support = ({ authorizedUser }) => {
  return (
    <PrivateTemplate headerTitle="Support">
      <div className="max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="py-6 px-4 sm:px-6 lg:col-span-2 lg:py-12 lg:px-8 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h3 className="text-xl font-extrabold text-gray-900 sm:text-2xl">Contact us</h3>
            <p className="mt-3 leading-6 text-gray-500">
              Questions about the app? Need technical support?{' '}
              <EmailLink className="underline hover:text-gray-900" to="support@fomoro.com" subject="Fomoro Support">
                Send us an email
              </EmailLink>
              . We'll figure out how to help.
            </p>
            <dl className="mt-8 ">
              <div className="mt-6">
                <dt className="sr-only">Technical Support</dt>
                <dd className="flex">
                  <EnvelopeIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <EmailLink
                    to="support@fomoro.com"
                    subject="Fomoro Support"
                    className="hover:underline hover:text-gray-900 ml-3"
                  >
                    support@fomoro.com
                  </EmailLink>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Sales</dt>
                <dd className="flex">
                  <EnvelopeIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <EmailLink
                    to="sales@fomoro.com"
                    subject="Fomoro Sales"
                    className="hover:underline hover:text-gray-900 ml-3"
                  >
                    sales@fomoro.com
                  </EmailLink>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Support;
