import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import authReducer from './slices/authSlice';
import companyReducer from './slices/companySlice';
import devicesReducer from './slices/devicesSlice';
import usersReducer from './slices/usersSlice';
import framesReducer from './slices/framesSlice';
import jobsReducer from './slices/jobsSlice';
import modelsReducer from './slices/modelsSlice';
import productsReducer from './slices/productsSlice';
import reportsReducer from './slices/reportsSlice';
import statusReducer from './slices/statusSlice';
import streamsReducer from './slices/streamsSlice';

const middleware = [...getDefaultMiddleware()];

export default configureStore({
  reducer: {
    auth: authReducer,
    company: companyReducer,
    devices: devicesReducer,
    users: usersReducer,
    streams: streamsReducer,
    frames: framesReducer,
    jobs: jobsReducer,
    models: modelsReducer,
    products: productsReducer,
    reports: reportsReducer,
    status: statusReducer,
  },
  middleware,
});
