import React from 'react';
import classNames from 'utils/classNames';

export const ButtonGroup = props => {
  const { items, changeHandler, checkedItems, children = null } = props;
  return !items || items.length === 0 ? (
    children
  ) : (
    <span className="flex-wrap flex">
      {items.map((item, i) => {
        return (
          <button
            key={item.id}
            onClick={() => changeHandler(item.id)}
            type="button"
            className={classNames(
              checkedItems.includes(item.id) ? ' bg-indigo-800 text-white' : ' bg-white text-gray-700',
              'shadow-sm rounded-md my-1 mr-2 relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium hover:z-10 hover:border-indigo-300 hover:outline-none hover:ring-1 hover:ring-indigo-300 '
            )}>
            {item.name}
          </button>
        );
      })}
    </span>
  );
};

export default ButtonGroup;
