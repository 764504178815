import React from 'react';

export const EmailLink = props => {
  const { to, subject, body, children, className } = props;

  let href = `mailto:${to}`;
  if (subject) href = `${href}?subject=${subject}`;
  if (body) href = `${href}?body=${body}`;

  return (
    <a className={className} href={href}>
      {children}
    </a>
  );
};

export default EmailLink;
