import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import PublicTemplate from '../components/PublicTemplate';
import WarningBanner from '../components/WarningBanner';
import Logo from '../components/Logo';

import { signIn } from 'state/slices/authSlice';
import { LockClosedIcon } from '@heroicons/react/24/solid';

export const SignIn = () => {
  const { message, status } = useSelector(state => state.status);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => {
      dispatch(signIn(values));
    },
  });

  return (
    <PublicTemplate>
      <div className="flex flex-col h-full">
        {status === 'rejected' && <WarningBanner type="danger" text={message} />}
        <div className="flex-1 flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <Logo className="mx-auto h-12 w-auto" fill="#DC2626" alt="Fomoro Manufacturing Logo" />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Or{' '}
                <Link to="/signup" className="font-medium text-red-600 hover:text-red-500">
                  signup for a new account
                </Link>
              </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="shadow bg-white sm:rounded-lg">
                <form onSubmit={formik.handleSubmit}>
                  <div className="sm:px-10 py-8 space-y-3 px-4">
                    <input type="hidden" name="remember" defaultValue="false" />
                    <div className="rounded-md shadow-sm -space-y-px">
                      <div>
                        <label htmlFor="email" className="sr-only">
                          Email address
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-700 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          placeholder="Email address"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                      </div>
                      <div>
                        <label htmlFor="password" className="sr-only">
                          Password
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-700 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          placeholder="Password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center"></div>
                      <div className="text-sm">
                        <Link to="/forgot-password" className="font-medium text-red-600 hover:text-red-500">
                          Forgot your password?
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="sm:rounded-b-lg py-3 sm:px-10 px-4 bg-gray-50 text-right ">
                    <button
                      type="submit"
                      disabled={status === 'pending'}
                      className="group py-2 px-4 relative w-full flex justify-center  border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        {/* Heroicon name: solid/lock-closed */}
                        <LockClosedIcon className="h-5 w-5 text-gray-500 group-hover:text-gray-400" />
                      </span>
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PublicTemplate>
  );
};

export default SignIn;
