import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';

import { getFrames } from 'state/slices/framesSlice';

import getJobSettingsNav from '../navs/jobSettingsNav';
import getCameraNav from '../navs/cameraNav';

import PrivateTemplate from '../components/PrivateTemplate';
import EmptyState from '../components/EmptyState';
import InterestRegionEditor from '../components/InterestRegionEditor';
import Tabs from '../components/Tabs';

import classNames from 'utils/classNames';

const shuffle = array => {
  // fisher-yates shuffle
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

const filterFrames = (frames, cameraId) => {
  return shuffle(frames)
    .filter(frame => frame.frameId.endsWith(cameraId))
    .reduce((frames, frame, index, frameData) => {
      const { frameId } = frame;
      const previous = frameData[index - 1] || {};
      const next = frameData[index + 1] || {};
      const linkedFrame = Object.assign(
        {
          cameraId,
          next: next?.frameId,
          previous: previous?.frameId,
        },
        frame
      );
      frames[frameId] = linkedFrame;
      return frames;
    }, {});
};

const LabelActions = ({ jobId, frame }) => {
  const { cameraId, previous, next } = frame;
  return (
    <div className="flex flex-col w-full justify-center">
      <div className="flex justify-between">
        <nav aria-label="Pagination">
          <span className="ml-3 inline-flex relative z-0 shadow-sm rounded-md">
            <Link
              to={`/job/${jobId}/settings-rois/${cameraId}/${previous}`}
              className={classNames(
                previous
                  ? 'bg-white text-gray-500 hover:bg-gray-50 pressed:z-10 pressed:outline-none pressed:ring-1 pressed:ring-indigo-600 pressed:border-indigo-600'
                  : 'bg-gray-300 text-gray-100 pointer-events-none',
                'relative inline-flex items-center xl:px-4 px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium'
              )}>
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
            <Link
              to={`/job/${jobId}/settings-rois/${cameraId}/${next}`}
              className={classNames(
                next
                  ? 'bg-white text-gray-500 hover:bg-gray-50 pressed:z-10 pressed:outline-none pressed:ring-1 pressed:ring-indigo-600 pressed:border-indigo-600'
                  : 'bg-gray-300 text-gray-100 pointer-events-none',
                'text-sm font-medium -ml-px relative inline-flex items-center xl:px-4  px-2 py-2 rounded-r-md border border-gray-300'
              )}>
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
          </span>
        </nav>
      </div>
    </div>
  );
};

export const ROISetup = ({ job, sectionNav, sectionTitle, headerTitle }) => {
  const dispatch = useDispatch();
  const { jobId, section, paramA: cameraIdSlug, paramB: frameIdSlug } = useParams();
  const [frames, setFrames] = useState({});
  const { cameraIds } = job;

  const allFrames = useSelector(state => state.frames.items[jobId]) ?? {};
  const frameList = Object.values(allFrames);

  const frame = frames?.[frameIdSlug] ?? Object.values(frames)[0];
  const image = frame?.images?.['original'];

  useEffect(() => {
    if (frameList.length === 0) return;
    const firstFrameId = frameList[0]['frameId'];
    const cameraId = cameraIdSlug ?? firstFrameId.split('-')[1];
    const filteredFrames = filterFrames(frameList, cameraId);
    setFrames(filteredFrames);
  }, [jobId, cameraIdSlug, frameList.length]); // eslint-disable-line

  useEffect(() => {
    const query = { sort: 'reverse', limit: 100 };
    dispatch(getFrames({ jobId, query }));
  }, [jobId]); // eslint-disable-line

  return (
    <PrivateTemplate
      headerTitle={`${headerTitle} › ROI Settings`}
      sectionTitle={sectionTitle}
      sectionNav={sectionNav}
      headerActions={frame && <LabelActions jobId={jobId} frame={frame} />}>
      <Tabs nav={getJobSettingsNav(jobId, 'settings-rois', job)} />
      {cameraIds && <Tabs nav={getCameraNav('job', jobId, section, cameraIds, frame?.cameraId)} />}

      {image ? (
        <InterestRegionEditor cameraId={frame.cameraId} src={image} job={job} />
      ) : (
        <EmptyState
          title="No Frames"
          description="Please activate your job and capture a few products before configuring your intrest regions."
          cta="Activate Job"
          to={`/job/${jobId}/settings`}
        />
      )}
    </PrivateTemplate>
  );
};

export default ROISetup;
