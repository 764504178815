import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import compareVersions from 'compare-versions';

import { getDevice, selectDevice, updateComponent } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';

import DeviceSettings from './DeviceSettings';
import DeviceJobs from './DeviceJobs';
import Cameras from './Cameras';
import Calibration from './Calibration';

import getDeviceNav from '../navs/deviceNav';

export const Device = props => {
  const dispatch = useDispatch();
  const { deviceId, section } = useParams();

  const getSelectedDevice = useMemo(selectDevice, []);
  const device = useSelector(state => getSelectedDevice(state, { deviceId }));

  const { status } = useSelector(state => state.status);

  useEffect(() => {
    dispatch(getDevice({ deviceId }));
  }, [deviceId]); // eslint-disable-line

  const updateComponentHandler = () => {
    dispatch(updateComponent({ deviceId, componentName: 'QualityControl' }));
  };

  const sectionNav = getDeviceNav(deviceId, section);

  const updateAvailible = Object.values(device?.components || {}).find(component => {
    return compareVersions(component.version, component.latestVersion) === 1;
  });

  const headerActions = updateAvailible && (
    <div className="sm:flex-1 sm:flex items-center justify-between">
      <button
        disabled={status === 'pending'}
        onClick={() => updateComponentHandler()}
        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
      >
        Update
      </button>
    </div>
  );

  const deviceCheck = Object.keys(device).length > 0;

  const view = deviceCheck ? section : null;
  const title = device.deviceName ?? device.deviceId;

  switch (view) {
    case 'settings':
      return (
        <DeviceSettings
          device={device}
          headerActions={headerActions}
          headerTitle={title}
          sectionNav={sectionNav}
          sectionTitle="Manage Station"
        />
      );
    case 'jobs':
      return (
        <DeviceJobs
          headerTitle={title}
          device={device}
          headerActions={headerActions}
          sectionNav={sectionNav}
          sectionTitle="Manage Station"
        />
      );
    case 'cameras':
      return <Cameras device={device} headerTitle={title} sectionNav={sectionNav} sectionTitle="Manage Station" />;
    case 'calibration':
      return <Calibration device={device} headerTitle={title} sectionNav={sectionNav} sectionTitle="Manage Station" />;
    default:
      return <PrivateTemplate headerActions={headerActions} sectionNav={sectionNav} sectionTitle="Manage Station" />;
  }
};

export default Device;
