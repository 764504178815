import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SignIn from './public/SignIn';
import SignUp from './public/SignUp';
import ConfirmSignUp from './public/ConfirmSignUp';
import ResendSignUp from './public/ResendSignUp';
import ResetPassword from './public/ResetPassword';
import ForgotPassword from './public/ForgotPassword';
import Public404 from './public/Public404';

export const PublicRoutes = () => {
  return (
    <Switch>
      <Redirect from="/signin" to="/" />
      <Route exact path="/" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/confirm-signup/:code?" component={ConfirmSignUp} />
      <Route exact path="/resend-signup" component={ResendSignUp} />
      <Route exact path="/reset-password/:code?" component={ResetPassword} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route component={Public404} />
    </Switch>
  );
};

export default PublicRoutes;
