import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

export const updateCompany = createAsyncThunk('company/updateCompany', async (payload, thunkAPI) => {
  try {
    return await API.put('api', '/company', { body: payload });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getCompany = createAsyncThunk('company/getCompany', async (payload, thunkAPI) => {
  try {
    return await API.get('api', '/company');
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    company: {
      companyName: '',
      addressLineOne: '',
      addressLineTwo: '',
      addressCity: '',
      addressState: '',
      addressZip: '',
      phone: '',
      website: '',
      industry: '',
    },
  },
  reducers: {
    'getCompany/fulfilled': (state, action) => {
      const company = action.payload;
      state.company = company;
    },
    'updateCompany/fulfilled': (state, action) => {
      const company = action.payload;
      state.company = company;
    },
  },
  extraReducers: {},
});

export default companySlice.reducer;
