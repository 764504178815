/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

export const Pagination = props => {
  const { pageData, baseUrl, urlFilter } = props;
  const { total, pages, pageNumber, itemsPerPage } = pageData;
  const previousPageNumber = pageNumber - 1;
  const nextPageNumber = pageNumber + 1;
  const pageRange = 5;
  const range = Math.floor(pageRange / 2);

  let startPage = pageNumber - range;
  let endPage = pageNumber + range;

  if (startPage < 1) {
    startPage = 1;
    endPage = pages < pageRange ? pages : pageRange;
  }

  if (endPage > pages) {
    const offset = pages - pageRange + 1;
    endPage = pages;
    startPage = offset > 0 ? offset : 1;
  }

  // create a new array with the desired length
  const links = [...Array(endPage - startPage + 1)].map((_, index) => {
    const pageNumber = startPage + index;
    return (
      <Link
        key={pageNumber}
        to={`${baseUrl}/${pageNumber}/${urlFilter}`}
        className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        {pageNumber}
      </Link>
    );
  });

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between sm:px-6">
      {pages > 1 && (
        <div className="flex-1 flex justify-between sm:hidden">
          {pageNumber > 1 && (
            <Link
              to={`${baseUrl}/${previousPageNumber}/${urlFilter}`}
              className="mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Previous
            </Link>
          )}
          {pageNumber < pages && (
            <Link
              to={`${baseUrl}/${nextPageNumber}/${urlFilter}`}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Next
            </Link>
          )}
        </div>
      )}
      <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {itemsPerPage * previousPageNumber < total ? (
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{previousPageNumber * itemsPerPage + 1}</span> to{' '}
              <span className="font-medium">
                {itemsPerPage * pageNumber < total ? pageNumber * itemsPerPage : total}
              </span>{' '}
              of <span className="font-medium">{total}</span> results
            </p>
          ) : (
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">0</span> of <span className="font-medium">{total}</span> results.{' '}
              <Link to={`${baseUrl}/1/${urlFilter}`}>View results</Link>
            </p>
          )}
        </div>
        <div className="hidden sm:block">
          {pages > 1 && (
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <Link
                to={`${baseUrl}/1/${urlFilter}`}
                className={
                  startPage !== 1
                    ? 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                    : 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-300 hover:bg-gray-50'
                }
              >
                <span className="sr-only">First</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </Link>

              {links}

              <Link
                to={`${baseUrl}/${pages}/${urlFilter}`}
                className={
                  endPage !== pages
                    ? 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
                    : 'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-300 hover:bg-gray-50'
                }
              >
                <span className="sr-only">Last</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
