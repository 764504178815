import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { MagnifyingGlassIcon, UserIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

import { getUsers, selectUsers } from 'state/slices/usersSlice';

import getAccountNav from '../navs/accountNav';

import PrivateTemplate from '../components/PrivateTemplate';
import Pagination from '../components/Pagination';
import EmptyState from '../components/EmptyState';

export const Users = () => {
  const { filter, pageNumber } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const sectionNav = getAccountNav('user');

  const page = parseInt(pageNumber) || 1;
  const getSelectedUsers = useMemo(selectUsers, []);
  const pageData = useSelector(state => getSelectedUsers(state, { filter, page }));

  useEffect(() => {
    dispatch(getUsers());
  }, []); // eslint-disable-line

  const filterHandler = e => {
    const filter = encodeURIComponent(e.target.value);
    history.push(`/account/users/${page}/${filter}`);
  };

  const headerActions = (
    <div className="sm:flex-1 sm:flex items-center justify-between">
      <Link to="/account/invite" className="mr-8 flex-1 flex items-center ">
        <PlusCircleIcon className="mr-2 h-5 w-5 hover:text-indigo-900 text-indigo-400" aria-hidden="true" />
        <span className="text-gray-900 hover:underline">Invite User</span>
      </Link>

      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="search"
          className="focus:ring-indigo-700 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search"
          defaultValue={filter}
          onChange={filterHandler}
        />
      </div>
    </div>
  );

  return (
    <PrivateTemplate
      headerTitle="Account › Users"
      headerActions={headerActions}
      sectionTitle="Manage Account"
      sectionNav={sectionNav}
    >
      <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          {pageData.items.length === 0 ? (
            <EmptyState
              title="No Users"
              description="Get started by adding a new user."
              cta="Add User"
              to="/account/users/add"
            />
          ) : (
            <div className="overflow-scroll border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Title
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {pageData.items.map((user, i) => (
                    <tr key={i}>
                      <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-8 w-8">
                            {user.isAdmin ? (
                              <UserIcon className="h-6 w-6 text-gray-400" />
                            ) : (
                              <UserIcon className="h-6 w-6 text-gray-200" />
                            )}
                          </div>
                          <div className="ml-2 lg:ml-6">
                            <div className="text-sm font-medium ">
                              {user.givenName} {user.familyName}
                            </div>
                            <div className="text-sm text-gray-500">{user.title}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        <div className="text-sm">{user.email}</div>
                      </td>
                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        <div className="text-sm">{user.title}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pageData={pageData} baseUrl={`/account/users/${filter}`} />
            </div>
          )}
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Users;
