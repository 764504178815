import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getReport, selectReport } from 'state/slices/reportsSlice';
import { getJobs, selectAllJobs } from 'state/slices/jobsSlice';
import { getUsers, selectAllUsers } from 'state/slices/usersSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import ReportSettings from './ReportSettings';

export const Report = props => {
  const dispatch = useDispatch();
  const { reportId } = useParams();

  const getSelectedReport = useMemo(selectReport, []);
  const report = useSelector(state => getSelectedReport(state, { reportId }));

  const getSelectedJobs = useMemo(selectAllJobs, []);
  const jobs = useSelector(state => getSelectedJobs(state));

  const getSelectedUsers = useMemo(selectAllUsers, []);
  const users = useSelector(state => getSelectedUsers(state));

  useEffect(() => {
    dispatch(getJobs());
    dispatch(getUsers());
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(getReport({ reportId }));
  }, [reportId]); // eslint-disable-line

  const reportCheck = Object.keys(report).length > 0;

  const title = report.reportName ?? reportId;
  const view = reportCheck ? 'settings' : null;

  switch (view) {
    case 'settings':
      return (
        <ReportSettings report={report} headerTitle={title} jobs={jobs} users={users} sectionTitle="Manage Report" />
      );
    default:
      return <PrivateTemplate />;
  }
};

export default Report;
