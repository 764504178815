import { BuildingOfficeIcon, UsersIcon, UserIcon, LockClosedIcon } from '@heroicons/react/24/outline';

export const getAccountNav = currentSection => {
  const current = currentSection || 'company';
  const nav = [];
  nav.push({
    name: 'Company',
    to: `/account/company`,
    icon: BuildingOfficeIcon,
    current: current === 'company',
  });
  nav.push({
    name: 'Users',
    to: `/account/users`,
    icon: UsersIcon,
    current: current === 'users',
  });
  nav.push({
    name: 'Profile',
    to: `/account/profile`,
    icon: UserIcon,
    current: current === 'profile',
  });
  nav.push({
    name: 'Change Password',
    to: `/account/change-password`,
    icon: LockClosedIcon,
    current: current === 'change-password',
  });

  return nav;
};

export default getAccountNav;
