import React from 'react';

import Canvas from '../components/Canvas';

const drawHandler = (context, options) => {
  const canvas = context.canvas;
  const { alpha, scores, scale } = options;

  context.clearRect(0, 0, canvas.width, canvas.height);
  if (scores && scores.length > 0) {
    // draw a dark translucent overlay across the entire canvas
    context.fillStyle = `rgba(0, 0, 0, ${alpha})`;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // for each interest region, clear the dark overlay and redraw a new overlay
    // using the attention score to alter the darkness
    scores.forEach(([score, region]) => {
      const [startY, endY, startX, endX] = region;

      const x = Math.round(startX * scale.x);
      const y = Math.round(startY * scale.y);
      const width = Math.round((endX - startX) * scale.x);
      const height = Math.round((endY - startY) * scale.y);

      context.clearRect(x, y, width, height);
      context.fillStyle = `rgba(0, 0, 0, ${(1 - score) * alpha})`;
      context.fillRect(x, y, width, height);
    });
  }
};

export const AttentionScores = ({ src, options = {} }) => {
  const defaults = {
    alpha: 0,
    strokeOffset: 3,
  };

  const canvasOptions = Object.assign({}, defaults, options);
  return (
    <div className="bg-contain bg-no-repeat" style={{ backgroundImage: `url(${src})` }}>
      <Canvas draw={drawHandler} options={canvasOptions} />
    </div>
  );
};

export default AttentionScores;
