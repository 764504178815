import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { FaceSmileIcon, FaceFrownIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import { getProducts, selectMeasurements } from 'state/slices/productsSlice';

import useInterval from 'utils/useInterval';

import Pagination from '../components/Pagination';
import PrivateTemplate from '../components/PrivateTemplate';
import EmptyState from '../components/EmptyState';
import Tabs from '../components/Tabs';

import getMeasurementsNav from '../navs/measurementsNav';

export const Measurements = props => {
  const dispatch = useDispatch();
  const { jobId, paramA: pageSlug, paramB: labelSlug = 'review' } = useParams();

  const { sectionNav, headerTitle, sectionTitle } = props;
  const page = parseInt(pageSlug, 10) || 1;

  const getSelectedProducts = useMemo(selectMeasurements, []);
  const pageData = useSelector(state => getSelectedProducts(state, { jobId, filter: labelSlug, page }));
  const next = useSelector(state => state.products.next[jobId]);

  const query = {
    sort: 'reverse',
  };

  useEffect(() => {
    dispatch(getProducts({ jobId, query }));
  }, [jobId]); // eslint-disable-line

  useInterval(() => {
    if (next && pageData.total < 500) query.next = next;
    dispatch(getProducts({ jobId, query }));
  }, 10 * 1000);

  const products = pageData.items.map(product => {
    const { productId, images, labeledMeasurements, toleranceEvaluation } = product;
    const [image, ...rest] = Object.values(images); // eslint-disable-line

    let icon;
    switch (toleranceEvaluation) {
      case 'GOOD':
        icon = <FaceSmileIcon className="text-green-500 h-6 w-6" />;
        break;
      case 'BAD':
        icon = <FaceFrownIcon className="text-red-500 h-6 w-6" />;
        break;
      default:
        icon = <QuestionMarkCircleIcon className="text-yellow-500 h-6 w-6" />;
    }
    return (
      <li key={productId} className="relative">
        <Link
          className="group block w-full rounded-md border border-gray-50 hover:ring-1 hover:ring-offset-2 hover:ring-offset-gray-100 hover:ring-gray-500"
          to={`/job/${jobId}/size/${productId}/${labelSlug}`}
        >
          {!labeledMeasurements && (
            <span className="p-1 rounded-md bg-black/60 flex absolute top-1 right-1 z-50 text-yellow-400">
              <span className="pr-1 hidden text-sm text-white group-hover:inline">Needs Review</span>
              <ExclamationTriangleIcon className="h-6 w-6" />
            </span>
          )}
          <img src={image['thumbnail']} alt="" className="object-contain rounded-md  pointer-events-none" />
        </Link>
        <span className="mt-2 flex items-center justify-center">
          {icon}
          <span className="ml-1 block truncate text-sm font-semibold">{toleranceEvaluation}</span>
        </span>
      </li>
    );
  });

  return (
    <PrivateTemplate headerTitle={`${headerTitle} › Sizes`} sectionNav={sectionNav} sectionTitle={sectionTitle}>
      <Tabs nav={getMeasurementsNav(jobId, labelSlug)} />

      <div className="bg-white flex flex-col flex-1 max-h-screen">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border-b border-gray-200">
            {pageData.items.length > 0 ? (
              <div className="relative h-full">
                <Pagination pageData={pageData} baseUrl={`/job/${jobId}/products`} urlFilter={labelSlug} />
                <section className=" sm:mb-8" aria-labelledby="gallery-heading">
                  <ul className="px-4 sm:px-6 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:grid-cols-4 xl:gap-x-8">
                    {products}
                  </ul>
                </section>
                <Pagination pageData={pageData} baseUrl={`/job/${jobId}/products`} urlFilter={labelSlug} />
              </div>
            ) : (
              <div className="border-t border-b border-gray-200">
                <EmptyState title="No Products" description="Please capture a few products" />
              </div>
            )}
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Measurements;
