import React from 'react';

export const Logo = props => {
  return (
    <svg
      className={props.className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512pt"
      height="512pt"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0,512) scale(0.1,-0.1)" fill={props.fill || '#fff'} stroke={props.stroke || 'none'}>
        {!!props.simple && (
          <path
            d="M2335 5100 c-418 -38 -834 -185 -1185 -419 -502 -334 -856 -811
-1036 -1395 -75 -245 -99 -422 -99 -736 0 -281 11 -383 66 -610 220 -902 941
-1631 1839 -1859 265 -67 340 -76 645 -76 305 0 384 10 640 75 744 189 1364
710 1688 1416 216 471 279 1008 181 1540 -120 656 -522 1264 -1086 1639 -321
215 -687 355 -1072 410 -123 18 -457 27 -581 15z m575 -75 c747 -115 1375
-522 1769 -1147 600 -952 480 -2205 -287 -3021 -404 -429 -870 -675 -1467
-774 -171 -28 -574 -26 -750 5 -541 94 -1002 329 -1375 702 -377 377 -624 864
-705 1390 -22 146 -31 457 -16 605 48 483 216 907 513 1298 83 108 280 310
393 402 395 321 849 502 1400 559 84 9 424 -4 525 -19z"
          />
        )}
        <path
          d="M2243 4780 c-484 -74 -925 -294 -1263 -630 -186 -186 -327 -382 -436
-605 -271 -557 -304 -1192 -92 -1769 284 -775 975 -1331 1803 -1452 164 -24
458 -24 620 0 855 129 1543 698 1818 1503 143 417 158 909 41 1327 -147 525
-475 974 -930 1276 -278 184 -623 312 -957 355 -164 21 -448 18 -604 -5z m652
-95 c757 -118 1395 -629 1679 -1345 51 -131 87 -256 117 -415 30 -157 37 -485
15 -648 -101 -723 -529 -1324 -1175 -1651 -190 -96 -397 -164 -611 -201 -146
-26 -486 -31 -635 -11 -394 55 -762 211 -1067 454 -440 351 -729 867 -798
1426 -16 132 -13 425 5 553 58 387 196 717 432 1024 79 104 258 287 363 370
294 236 690 404 1055 448 50 6 101 13 115 15 53 10 410 -4 505 -19z"
        />
        {!!props.simple && (
          <path
            d="M2430 4493 c-605 -46 -1149 -363 -1487 -869 -204 -304 -323 -698
-323 -1068 0 -567 248 -1110 675 -1474 296 -253 642 -406 1035 -458 151 -20
404 -14 550 12 731 129 1303 620 1530 1314 175 532 112 1107 -173 1592 -244
414 -658 739 -1112 871 -232 68 -479 96 -695 80z m360 -133 c609 -75 1149
-466 1415 -1022 102 -215 155 -412 174 -657 42 -527 -172 -1079 -561 -1446
-716 -675 -1811 -669 -2522 14 -435 419 -635 1048 -522 1642 120 622 550 1135
1146 1364 100 39 306 91 405 104 101 12 366 13 465 1z"
          />
        )}
        <path
          d="M2370 4180 c-200 -28 -370 -80 -535 -162 -455 -229 -767 -635 -877
-1141 -30 -139 -33 -493 -4 -632 63 -310 219 -609 434 -828 152 -155 289 -253
477 -342 225 -107 388 -145 650 -152 209 -6 340 10 517 61 582 170 1031 673
1144 1281 26 140 26 440 0 580 -65 355 -264 707 -530 936 -226 196 -474 318
-776 380 -110 22 -396 33 -500 19z m314 -150 c209 -20 361 -63 539 -151 359
-179 624 -484 749 -864 54 -161 71 -272 71 -460 0 -191 -17 -299 -74 -470
-107 -321 -327 -602 -615 -787 -138 -88 -326 -162 -504 -199 -162 -33 -406
-34 -568 -1 -305 62 -540 188 -762 411 -225 226 -356 470 -417 776 -24 122
-24 403 0 531 107 579 558 1052 1125 1180 181 40 296 49 456 34z"
        />
        {!!props.simple && (
          <path
            d="M2340 3865 c-329 -62 -608 -225 -804 -470 -88 -110 -123 -165 -175
-275 -90 -192 -123 -349 -123 -575 0 -362 130 -673 387 -930 113 -114 206
-180 350 -253 188 -94 374 -137 595 -136 221 1 398 43 591 140 374 187 643
547 713 954 21 121 21 348 1 466 -18 101 -73 271 -117 357 -146 289 -392 518
-695 643 -156 65 -266 86 -468 90 -128 2 -202 -1 -255 -11z m451 -191 c591
-112 1005 -707 904 -1300 -86 -508 -477 -887 -984 -955 -152 -20 -365 -1 -507
46 -340 114 -622 398 -728 733 -129 411 -33 836 261 1142 94 99 178 163 292
223 233 123 496 162 762 111z"
          />
        )}
        <path
          d="M2415 3565 c-223 -35 -400 -122 -561 -277 -246 -236 -354 -564 -299
-905 64 -395 353 -711 750 -820 90 -25 115 -28 260 -27 140 0 172 4 255 26
634 171 951 869 659 1450 -129 257 -375 458 -645 527 -156 41 -279 49 -419 26z
m395 -244 c123 -41 214 -96 306 -184 179 -169 263 -378 251 -621 -7 -137 -24
-206 -83 -322 -190 -382 -637 -548 -1035 -384 -219 91 -402 301 -465 535 -26
96 -27 322 -1 415 80 292 318 516 622 586 39 9 103 13 185 10 109 -3 137 -7
220 -35z"
        />
        {!!props.simple && (
          <path
            d="M2414 3255 c-271 -60 -486 -270 -549 -538 -21 -90 -19 -247 5 -341
58 -228 236 -422 459 -499 111 -39 277 -49 394 -23 207 46 386 188 482 380 60
121 78 210 72 351 -7 172 -64 314 -178 446 -57 66 -176 151 -257 184 -128 52
-299 68 -428 40z m287 -255 c127 -39 225 -123 286 -247 35 -70 38 -84 41 -179
4 -88 1 -113 -18 -167 -118 -333 -528 -431 -774 -185 -164 165 -188 401 -61
593 112 168 332 245 526 185z"
          />
        )}
        <path
          d="M2445 2947 c-198 -66 -321 -260 -288 -454 39 -227 249 -381 471 -345
222 37 368 234 341 460 -15 130 -106 256 -228 314 -58 28 -78 32 -161 35 -60
2 -110 -2 -135 -10z m157 -267 c73 -21 115 -113 81 -180 -42 -81 -142 -104
-203 -45 -89 85 -45 213 80 234 3 1 22 -4 42 -9z"
        />
      </g>
    </svg>
  );
};

export default Logo;
