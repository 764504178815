import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'utils/classNames';

import PrivateTemplate from '../components/PrivateTemplate';
import {
  UserCircleIcon,
  BuildingOfficeIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  ServerIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';

const items = [
  {
    title: 'Activate a Station',
    description: 'Register each station with your account. Once a station has been activated you can add jobs.',
    icon: ServerIcon,
    background: 'bg-pink-500 group-hover:bg-pink-600',
    to: '/stations/add',
  },
  {
    title: 'Manage your Stations',
    description: 'View stations added to your account for a birds-eye view of each node.',
    icon: RectangleStackIcon,
    background: 'bg-yellow-500 group-hover:bg-yellow-600',
    to: '/stations',
  },
  {
    title: 'Create a Job',
    description:
      'Add a new job for each part, process or product you want to inspect. Jobs are the heart of the system and let you easily switch between different inspection tasks.',
    icon: ClipboardDocumentCheckIcon,
    background: 'bg-green-500 group-hover:bg-green-600',
    to: '/jobs/add',
  },
  {
    title: 'Manage your Jobs',
    description:
      'View and search your jobs to calibrate cameras, label images, train & deploy models, and monitor inspection.',
    icon: ClipboardDocumentListIcon,
    background: 'bg-blue-500 group-hover:bg-blue-600',
    to: '/jobs',
  },
  {
    title: 'Setup your Company',
    description: 'Provide more detailed information about your company to make the support process faster and easier.',
    icon: BuildingOfficeIcon,
    background: 'bg-indigo-500 group-hover:bg-indigo-600',
    to: '/account/company',
  },
  {
    title: 'Edit your Profile',
    description: 'Provide more detailed information about you to make the support process faster and easier.',
    icon: UserCircleIcon,
    background: 'bg-purple-500 group-hover:bg-purple-600',
    to: '/account/profile',
  },
];

export const Dashboard = () => {
  return (
    <PrivateTemplate headerTitle="Dashboard">
      <div className="max-w-5xl py-6 px-4 sm:px-6 lg:px-8">
        <div className="space-y-5 divide-y divide-gray-200">
          <div className="space-y-8  sm:space-y-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Getting Started</h3>
            <div className="text-sm text-gray-500">
              <p className="mb-2">
                Below are some common tasks to get started.{' '}
                <Link to="/support" className="hover:text-gray-900 underline">
                  Contact support
                </Link>{' '}
                if you need assistance.
              </p>
            </div>
          </div>

          <ul className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
            {items.map((item, itemIdx) => (
              <li key={itemIdx} className="flow-root group">
                <div className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                  <div
                    className={classNames(
                      item.background,
                      'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
                    )}
                  >
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div>
                    <h3 className="group-hover:underline text-sm font-medium text-gray-900">
                      <Link to={item.to} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {item.title}
                        <span aria-hidden="true"> &rarr;</span>
                      </Link>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Dashboard;
