import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { FaceSmileIcon, FaceFrownIcon, QuestionMarkCircleIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

import { getJob, selectComponentState } from 'state/slices/jobsSlice';
import { getFrames, selectFrames } from 'state/slices/framesSlice';

import Pagination from '../components/Pagination';
import PrivateTemplate from '../components/PrivateTemplate';
import EmptyState from '../components/EmptyState';
import Tabs from '../components/Tabs';
import WarningBanner from '../components/WarningBanner';

import getLabelNav from '../navs/labelNav';

import useInterval from 'utils/useInterval';

const OFFSET = 60 * 60 * 24 * 30 * 1000; // 30 days in milliseconds

export const Labels = props => {
  const dispatch = useDispatch();
  const { jobId, paramA: pageSlug, paramB: labelSlug = 'review' } = useParams();
  const { job, sectionNav, headerTitle, sectionTitle } = props;

  const page = parseInt(pageSlug, 10) || 1;

  const getSelectedFrames = useMemo(selectFrames, []);
  const pageData = useSelector(state => getSelectedFrames(state, { jobId, filter: labelSlug, page }));

  const next = useSelector(state => state.frames.next[jobId]);

  const getSelectedState = useMemo(selectComponentState, []);
  const componentState = useSelector(state => getSelectedState(state, { jobId, filter: 'QualityControl' }));

  const reportedMode = componentState.reported.mode;
  const desiredMode = componentState.desired.mode;
  const inSync = reportedMode === desiredMode;

  const currentTimestamp = new Date().getTime();
  const query = {
    start: currentTimestamp + OFFSET,
    stop: currentTimestamp,
    quality: labelSlug.toUpperCase(),
    sort: 'reverse',
  };

  useEffect(() => {
    dispatch(getFrames({ jobId, query }));
  }, [jobId, labelSlug]); // eslint-disable-line

  useInterval(() => {
    if (next && pageData.total < 500) query.next = next;
    if (!inSync) dispatch(getJob({ jobId }));
    dispatch(getFrames({ jobId, query }));
  }, 10 * 1000);

  const frames = pageData.items.map(frame => {
    const needsReview = !frame.labeledQuality && frame.predictedQuality === 'UNCERTAIN';
    const displayLabel = frame.labeledQuality ?? frame.predictedQuality;
    let icon;
    switch (displayLabel) {
      case 'GOOD':
        icon = <FaceSmileIcon className="text-green-500 h-6 w-6" />;
        break;
      case 'BAD':
        icon = <FaceFrownIcon className="text-red-500 h-6 w-6" />;
        break;
      case 'IGNORE':
        icon = <NoSymbolIcon className="text-indigo-500 h-6 w-6" />;
        break;
      case 'UNCERTAIN':
      default:
        icon = <QuestionMarkCircleIcon className="text-yellow-500 h-6 w-6" />;
    }

    return (
      <li key={frame.frameId} className="relative">
        <Link
          className="group block w-full rounded-md border border-gray-50 hover:ring-1 hover:ring-offset-2 hover:ring-offset-gray-100 hover:ring-gray-500"
          to={`/job/${jobId}/label/${frame.frameId}/${labelSlug}`}>
          {needsReview && (
            <span className="p-1 rounded-md bg-black/60 flex absolute top-1 right-1 z-50 text-yellow-400">
              <span className="pr-1 hidden text-sm text-white group-hover:inline">Needs Review</span>
              <ExclamationTriangleIcon className="h-6 w-6" />
            </span>
          )}
          <img src={frame.images['thumbnail']} alt="" className="object-contain rounded-md pointer-events-none" />
        </Link>
        <span className="mt-2 flex items-center justify-center">
          {icon}
          <span className="ml-1 block truncate text-sm font-semibold">{displayLabel}</span>
        </span>
      </li>
    );
  });

  return (
    <PrivateTemplate headerTitle={`${headerTitle} › Labels`} sectionNav={sectionNav} sectionTitle={sectionTitle}>
      {job.device.activeJobId !== job.jobId && <WarningBanner text="Job is not active." />}

      <Tabs nav={getLabelNav(jobId, labelSlug)} />
      <div className="bg-white flex flex-col flex-1 max-h-screen">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border-b border-gray-200">
            {pageData.items.length > 0 ? (
              <div className="relative h-full">
                <Pagination pageData={pageData} baseUrl={`/job/${jobId}/labels`} urlFilter={labelSlug} />
                <section className=" sm:mb-8" aria-labelledby="gallery-heading">
                  <ul className="px-4 sm:px-6 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:grid-cols-4 xl:gap-x-8">
                    {frames}
                  </ul>
                </section>
                <Pagination pageData={pageData} baseUrl={`/job/${jobId}/labels`} urlFilter={labelSlug} />
              </div>
            ) : (
              <div className="border-t border-b border-gray-200">
                <EmptyState title="No Labels" description="Please capture a few products" />
              </div>
            )}
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Labels;
