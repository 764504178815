import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import capitalize from 'utils/capitalize';

import { updateDevice } from 'state/slices/devicesSlice';
import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

const validate = values => {
  const errors = {};
  if (!values.deviceName) {
    errors.deviceName = 'Required';
  }
  return errors;
};

export const DeviceSettings = props => {
  const { deviceId } = useParams();
  const dispatch = useDispatch();

  const { device, headerTitle, headerActions, sectionNav } = props;
  const { message, method, status, args } = useSelector(state => state.status);
  const { reported = {} } = device.state ?? {};

  const formik = useFormik({
    initialValues: {
      deviceName: device.deviceName,
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      dispatch(updateDevice({ deviceId, deviceName: values.deviceName }));
    },
  });

  const hardware = reported?.hardware ?? {};
  return (
    <PrivateTemplate
      headerTitle={`${headerTitle} › Settings`}
      headerActions={headerActions}
      sectionNav={sectionNav}
      sectionTitle="Manage Station"
    >
      {method === 'updateDevice' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully updated ${args.deviceName}`} />
      )}
      {method === 'updateDevice' && status === 'rejected' && <WarningBanner text={message} />}

      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="space-y-4 divide-y divide-gray-200">
          <div className="max-w-7xl w-full">
            <div className="space-y-4">
              <form onSubmit={formik.handleSubmit} className="max-w-2xl mt-6">
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                  <div className="sm:col-span-6">
                    <label htmlFor="deviceId" className="block text-sm font-medium text-gray-900">
                      Station Id
                    </label>
                    <input
                      type="text"
                      readOnly
                      name="deviceId"
                      id="deviceId"
                      value={device.deviceId}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-200 text-gray-700 sm:text-sm focus:ring-indigo-300 focus:border-indigo-200"
                    />
                  </div>

                  <div className="sm:col-span-6">
                    {formik.errors.deviceName && formik.touched.deviceName && <Error>{formik.errors.deviceName}</Error>}
                    <label htmlFor="deviceName" className="block text-sm font-medium text-gray-900">
                      Station Name
                    </label>
                    <input
                      type="text"
                      name="deviceName"
                      id="deviceName"
                      onChange={formik.handleChange}
                      value={formik.values.deviceName}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-gray-900 sm:text-sm focus:ring-indigo-700 focus:border-indigo-500"
                    />
                  </div>
                </div>

                <div className="pt-8 flex justify-end">
                  <button
                    type="submit"
                    disabled={status === 'pending'}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t bg-gray-100 border-gray-200 flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 lg:px-8 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Hardware
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <div className="ml-6"></div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                    <div className="">
                      <div className="w-8">Station Id</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="ml-6">
                      <div className="text-sm">{deviceId}</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                    <div className="">
                      <div className="w-8">Mode</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="ml-6">
                      <div className="text-sm">
                        {reported?.mode === 'ACTIVE' ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {capitalize(reported?.mode)}
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            {capitalize(reported?.mode)}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                    <div className="">
                      <div className="w-8">Status</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="ml-6">
                      <div className="text-sm">
                        {device.status === 'HEALTHY' ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {capitalize(device.status)}
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            {capitalize(device.status)}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
                {device.components &&
                  Object.entries(device.components).map(([key, values]) => (
                    <tr key={key}>
                      <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                        <div className="">
                          <div className="w-8">Software</div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="ml-6">
                          <div className="text-sm">
                            {key} ({values.version})
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}

                {hardware &&
                  Object.entries(hardware).map(([key, value]) => (
                    <tr key={key}>
                      <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                        <div className="">
                          <div className="w-8">{key === 'uuid' ? key.toUpperCase() : capitalize(key)}</div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="ml-6">
                          <div className="text-sm">{value}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default DeviceSettings;
