import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import PublicTemplate from '../components/PublicTemplate';
import WarningBanner from '../components/WarningBanner';
import Logo from '../components/Logo';

import { resendSignUp } from 'state/slices/authSlice';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
};

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

export const ResendSignUp = () => {
  const { message, method, status } = useSelector(state => state.status);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: values => {
      dispatch(resendSignUp(values));
    },
  });

  if (method === 'resendSignUp' && status === 'fulfilled') {
    history.push('/confirm-signup');
  }

  return (
    <PublicTemplate>
      <div className="flex flex-col h-full">
        {status === 'rejected' && <WarningBanner type="danger" text={message} />}
        <div className="flex-1 flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <Logo className="mx-auto h-12 w-auto" fill="#DC2626" alt="Fomoro Manufacturing Logo" />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Resend verification email</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="shadow bg-white sm:rounded-lg">
                <form onSubmit={formik.handleSubmit}>
                  <div className="sm:px-10 py-8 space-y-6 px-4">
                    {' '}
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <div className="mt-1">
                        {formik.errors.email && <Error>{formik.errors.email}</Error>}
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm"
                          placeholder="Email address"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />{' '}
                      </div>
                    </div>
                  </div>
                  <div className="sm:rounded-b-lg py-3 sm:px-10 px-4 bg-gray-50 text-right ">
                    <button
                      type="submit"
                      disabled={status === 'pending'}
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PublicTemplate>
  );
};

export default ResendSignUp;
