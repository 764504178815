import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';

import { getFrames, getFrame, updateFrame } from 'state/slices/framesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import AttentionScores from '../components/AttentionScores';
import ImageZoom from '../components/ImageZoom';
import LabelToggle from '../components/LabelToggle';

import classNames from 'utils/classNames';
import formatTimestamp from 'utils/formatTimestamp';
import linkItems from 'utils/linkItems';
import capitalize from 'utils/capitalize';

const HeaderActions = ({ jobId, frames = {}, frame = {}, qualitySlug }) => {
  const total = Object.keys(frames).length;
  const { previous, next, index } = frame;
  return (
    <div className="flex flex-col w-full justify-center">
      <div className="flex justify-between">
        <div className="sm:flex-1 sm:flex items-center justify-between">
          {index} of {total}
        </div>
        <nav aria-label="Pagination">
          <span className="ml-3 hidden md:inline-flex relative z-0 shadow-sm rounded-md">
            <Link
              to={`/job/${jobId}/label/${previous}/${qualitySlug}`}
              className={classNames(
                previous
                  ? 'bg-white text-gray-500 hover:bg-gray-50 pressed:z-10 pressed:outline-none pressed:ring-1 pressed:ring-indigo-600 pressed:border-indigo-600'
                  : 'bg-gray-300 text-gray-100 pointer-events-none',
                'relative inline-flex items-center xl:px-4 px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium'
              )}>
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Link>

            <Link
              to={`/job/${jobId}/label/${next}/${qualitySlug}`}
              className={classNames(
                next
                  ? 'bg-white text-gray-500 hover:bg-gray-50 pressed:z-10 pressed:outline-none pressed:ring-1 pressed:ring-indigo-600 pressed:border-indigo-600'
                  : 'bg-gray-300 text-gray-100 pointer-events-none',
                'text-sm font-medium -ml-px relative inline-flex items-center xl:px-4  px-2 py-2 rounded-r-md border border-gray-300'
              )}>
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Link>
          </span>
        </nav>
      </div>
    </div>
  );
};

export const Label = ({ job, sectionNav, sectionTitle, headerTitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { jobId, paramA: frameId, paramB: qualitySlug } = useParams();

  const allFrames = Object.values(useSelector(state => state.frames.items[jobId]) ?? {});
  const next = useSelector(state => state.frames.next[jobId]);

  const [frames, setFrames] = useState({});
  const storedFrame = useSelector(state => state.frames.items?.[jobId]?.[frameId]) ?? {};
  const frame = Object.assign({}, frames[frameId], storedFrame);
  const image = frame.images?.['original'];
  const { tags: frameTags, labeledQuality: frameQuality } = frame;
  const [tags, setTags] = useState(frameTags ?? []);
  const [labeledQuality, setLabeledQuality] = useState(frameQuality ?? '');

  const [query] = useState({
    quality: qualitySlug.toUpperCase(),
    sort: 'reverse',
  });

  useEffect(() => {
    setTags(frameTags ?? []);
    setLabeledQuality(frameQuality ?? '');
  }, [frameId, frameTags, frameQuality]); // eslint-disable-line

  useEffect(() => {
    const { quality } = query;
    const filteredFrames = ['GOOD', 'BAD', 'IGNORE'].includes(quality)
      ? allFrames.filter(item => item.labeledQuality === quality)
      : allFrames.filter(item => !item.labeledQuality);
    const linkedFrames = linkItems('frameId', filteredFrames);
    setFrames(linkedFrames);
  }, [jobId, query, allFrames.length]); // eslint-disable-line

  useEffect(() => {
    if (next && allFrames.length >= 500) return;
    const nextQuery = next ? Object.assign({}, query, { next }) : query;
    dispatch(getFrames({ jobId, query: nextQuery }));
  }, [jobId, query, next]); // eslint-disable-line

  useEffect(() => {
    dispatch(getFrame({ jobId, frameId }));
  }, [jobId, frameId]); // eslint-disable-line

  const handleSubmit = e => {
    e.preventDefault();
    const { tags: jobTags = [] } = job;
    const newTags = tags.filter(tag => jobTags.includes(tag));

    dispatch(updateFrame({ jobId, frameId, labeledQuality, tags: newTags }));
    if (frame.next) {
      const nextUrl = `/job/${jobId}/label/${frame.next}/${qualitySlug}`;
      history.push(nextUrl);
    }
  };
  const updateLabel = newQuality => {
    const qualityUpperCase = newQuality.toUpperCase();
    const newLabel = labeledQuality !== qualityUpperCase ? qualityUpperCase : '';
    setLabeledQuality(newLabel);
    if (newLabel !== 'BAD') setTags([]);
  };

  const updateTag = newTag => {
    setLabeledQuality('BAD');
    let newTags;
    if (tags.includes(newTag)) {
      newTags = tags.filter(tag => tag !== newTag);
    } else {
      newTags = tags.concat(newTag);
    }
    setTags(newTags);
  };

  const [timestamp, cameraId] = frameId.split('-');

  return (
    <PrivateTemplate
      headerTitle={`${headerTitle} › Labels › ${capitalize(frame.labeledQuality ?? frame.predictedQuality)}`}
      headerActions={<HeaderActions jobId={jobId} frames={frames} frame={frame} qualitySlug={qualitySlug} />}
      sectionNav={sectionNav}
      sectionTitle={sectionTitle}>
      <div className="md:grid md:grid-cols-12">
        <div className="md:col-span-8 bg-white border-b border-gray-200 lg:border-transparent">
          <div className="w-full">
            {image && (
              <ImageZoom image={image}>
                <AttentionScores src={image} />
              </ImageZoom>
            )}
            <table className="hidden md:flex min-w-full divide-y divide-gray-200">
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm">Verified Label</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm capitalize">{frame.labeledQuality}</div>
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm">Original Label</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm capitalize">{frame.predictedQuality}</div>
                  </td>
                </tr>
                {frame.tags && (
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm">Tags</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm capitalize">{frame.tags.toString()}</div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm">Camera</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm capitalize">{cameraId}</div>
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm">Date</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm">{formatTimestamp(timestamp)}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <aside className="bg-white border-l border-gray-100 col-span-12 block md:col-span-4 pb-4">
          <form className="px-4" onSubmit={handleSubmit}>
            <div className="border-b border-gray-200 divide-y divide-gray-200">
              <div className="relative flex items-start py-4">
                <LabelToggle value="Good" changeHandler={updateLabel} enabled={labeledQuality === 'GOOD'} />
              </div>
              <div className="relative flex items-start py-4">
                <LabelToggle value="Ignore" changeHandler={updateLabel} enabled={labeledQuality === 'IGNORE'} />
              </div>
              <div>
                <div className="relative flex items-start py-4">
                  <LabelToggle value="Bad" changeHandler={updateLabel} enabled={labeledQuality === 'BAD'} />
                </div>
                <div>
                  {job.tags &&
                    job.tags.map(tag => (
                      <div key={`${frameId}-${tag}`} className="relative flex items-start py-4 pl-4">
                        <LabelToggle
                          value={tag}
                          changeHandler={updateTag}
                          enabled={tags.includes(tag) && labeledQuality === 'BAD'}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                  Save
                </button>
              </div>
            </div>
          </form>
        </aside>
      </div>
    </PrivateTemplate>
  );
};

export default Label;
