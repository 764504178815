import { useSelector } from 'react-redux';

import SuccessMessage from '../components/SuccessMessage';

export const FlashMessage = props => {
  const { message, status } = useSelector(state => state.status);
  if (!message || status !== 'fulfilled') {
    return null;
  }
  return <SuccessMessage message={message} />;
};

export default FlashMessage;
