import React, { useRef, useEffect } from 'react';
import * as Plot from '@observablehq/plot';

const Chart = props => {
  const { data, options, ...rest } = props;
  const chartRef = useRef();

  const defaults = {
    style: {
      background: 'transparent',
    },
    color: {
      type: 'diverging',
      scheme: 'burd',
    },
  };

  const chartOptions = Object.assign(defaults, options);

  useEffect(() => {
    if (data === undefined) return;
    const chart = Plot.plot(chartOptions);
    chartRef.current.append(chart);
    return () => chart.remove();
  }, [data]); // eslint-disable-line

  return <div ref={chartRef} {...rest} />;
};

export default Chart;
