export const scaleBoundingBox = ({ x, y, width, height }, { x: scaleX, y: scaleY }) => {
  return {
    x: x * scaleX,
    y: y * scaleY,
    width: width * scaleX,
    height: height * scaleY,
  };
};

export const getHandles = (boundingBox, { handleSize }) => {
  const sizeHalf = handleSize / 2;
  const boundingBoxWidthHalf = boundingBox.width / 2;
  const boundingBoxHeightHalf = boundingBox.height / 2;
  return [
    {
      x: boundingBox.x - sizeHalf,
      y: boundingBox.y + boundingBoxHeightHalf - sizeHalf,
      width: handleSize,
      height: handleSize,
      type: 'leftEdge',
      cursor: 'ew-resize',
    },
    {
      x: boundingBox.x + boundingBox.width - sizeHalf,
      y: boundingBox.y + boundingBoxHeightHalf - sizeHalf,
      width: handleSize,
      height: handleSize,
      type: 'rightEdge',
      cursor: 'ew-resize',
    },
    {
      x: boundingBox.x + boundingBoxWidthHalf - sizeHalf,
      y: boundingBox.y - sizeHalf,
      width: handleSize,
      height: handleSize,
      type: 'topEdge',
      cursor: 'ns-resize',
    },
    {
      x: boundingBox.x + boundingBoxWidthHalf - sizeHalf,
      y: boundingBox.y + boundingBox.height - sizeHalf,
      width: handleSize,
      height: handleSize,
      type: 'bottomEdge',
      cursor: 'ns-resize',
    },
    {
      x: boundingBox.x - sizeHalf,
      y: boundingBox.y - sizeHalf,
      width: handleSize,
      height: handleSize,
      type: 'topLeftCorner',
      cursor: 'nwse-resize',
    },
    {
      x: boundingBox.x + boundingBox.width - sizeHalf,
      y: boundingBox.y - sizeHalf,
      width: handleSize,
      height: handleSize,
      type: 'topRightCorner',
      cursor: 'nesw-resize',
    },
    {
      x: boundingBox.x - sizeHalf,
      y: boundingBox.y + boundingBox.height - sizeHalf,
      width: handleSize,
      height: handleSize,
      type: 'bottomLeftCorner',
      cursor: 'nesw-resize',
    },
    {
      x: boundingBox.x + boundingBox.width - sizeHalf,
      y: boundingBox.y + boundingBox.height - sizeHalf,
      width: handleSize,
      height: handleSize,
      type: 'bottomRightCorner',
      cursor: 'nwse-resize',
    },
  ];
};

export const getActiveBoxIndex = (options, e) => {
  const { offsetX, offsetY } = e;
  const { boundingBoxes, scale, padding } = options;
  return boundingBoxes.findIndex(({ x, y, width, height }) => {
    return (
      offsetX >= (x - padding) * scale.x &&
      offsetX <= (x + width + padding) * scale.x &&
      offsetY >= (y - padding) * scale.y &&
      offsetY <= (y + height + padding) * scale.y
    );
  });
};

export const getActiveHandle = (boundingBox, options, e) => {
  const { offsetX, offsetY } = e;
  const { scale, handlePadding } = options;
  const handles = getHandles(boundingBox, options);
  return handles.find(({ x, y, width, height }) => {
    return (
      offsetX >= (x - handlePadding) * scale.x &&
      offsetX <= (x + width + handlePadding) * scale.x &&
      offsetY >= (y - handlePadding) * scale.y &&
      offsetY <= (y + height + handlePadding) * scale.y
    );
  });
};
