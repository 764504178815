import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

export const signUp = createAsyncThunk('auth/signUp', async payload => {
  const { email, password, givenName, familyName } = payload;
  await Auth.signUp({
    username: email,
    password: password,
    attributes: {
      email: email,
      given_name: givenName,
      family_name: familyName,
    },
  });
});

export const confirmSignUp = createAsyncThunk('auth/confirmSignUp', async payload => {
  const { email, code } = payload;
  await Auth.confirmSignUp(email, code);
});

export const signIn = createAsyncThunk('auth/signIn', async payload => {
  const { email, password } = payload;
  await Auth.signIn(email, password);
  const user = await Auth.currentAuthenticatedUser();
  return user.attributes;
});

export const resendSignUp = createAsyncThunk('auth/resendSignUp', async payload => {
  const { email } = payload;
  await Auth.resendSignUp(email);
});

export const signOut = createAsyncThunk('auth/signOut', async thunkAPI => {
  await Auth.signOut();
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async payload => {
  const { email } = payload;
  await Auth.forgotPassword(email);
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async payload => {
  const { email, code, newPassword } = payload;
  await Auth.forgotPasswordSubmit(email, code, newPassword);
});

export const changePassword = createAsyncThunk('auth/changePassword', async payload => {
  const { oldPassword, newPassword } = payload;
  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, oldPassword, newPassword);
});

export const getAuthUser = createAsyncThunk('auth/getAuthUser', async payload => {
  const user = await Auth.currentAuthenticatedUser();
  return user.attributes;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
  },
  reducers: {
    'signIn/fulfilled': (state, action) => {
      state.user = action.payload;
    },
    'signOut/fulfilled': (state, action) => {
      state.user = null;
    },
    'getAuthUser/fulfilled': (state, action) => {
      state.user = action.payload;
    },
    'getAuthUser/rejected': (state, action) => {
      state.user = null;
    },
  },
});

export default authSlice.reducer;
