import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'utils/classNames';

export const SectionNav = ({ sectionNav, title }) => {
  const links = sectionNav.map(({ icon: Icon, to, current, name }) => {
    return (
      <Link
        key={name}
        to={to}
        className={classNames(
          current
            ? 'bg-gray-900 text-white sm:bg-gray-200 sm:bg-opacity-50'
            : 'hover:bg-gray-600 hover:text-white sm:hover:text-gray-900 sm:hover:bg-gray-100 sm:hover:bg-opacity-50',
          'flex flex-grow sm:p-6 py-6 items-center sm:text-gray-900 sm:border-b sm:border-gray-200'
        )}
        aria-current={current}
      >
        <Icon className="flex-shrink-0 m-auto sm:m-0 sm:-mt-0.5 h-6 w-6 text-gray-400" />
        <div className="ml-3 text-sm hidden md:block">
          <p className="font-medium text-gray-900">{name}</p>
        </div>
      </Link>
    );
  });

  return (
    <section className="bg-white sm:h-full border-gray-200 border-r sm:overflow-scroll">
      {/* Desktop Nav */}
      <nav aria-label="Sections" className="hidden max-w-48 lg:w-64  sm:flex sm:flex-col">
        <div className="hidden md:flex h-16 px-6 bg-gray-50 border-b border-gray-200 items-center">
          <p className="text-lg font-medium text-gray-900">{title}</p>
        </div>

        <div className="flex">
          <div className="flex-grow bg-white">{links}</div>
        </div>
      </nav>
      {/* Mobile Nav */}
      <nav className="border-b border-gray-200 sm:hidden">
        <div className="-mt-px flex items-center justify-between">{links}</div>
      </nav>
    </section>
  );
};

export default SectionNav;
