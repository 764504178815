import React, { useEffect, useRef } from 'react';
import WebRTCViewer from 'utils/WebRTCViewer';

export const WebRTCVideo = props => {
  const { clientId, width, height } = props;
  const videoEl = useRef(null);

  useEffect(() => {
    const viewer = WebRTCViewer.streams[clientId];
    videoEl.current.srcObject = viewer;
  });

  return (
    <video
      className="aspect-h-1 w-full"
      ref={videoEl}
      width={width}
      height={height}
      preload="metadata"
      disablePictureInPicture
      autoPlay
      playsInline
      controls
      muted
    />
  );
};

export default React.memo(WebRTCVideo);
