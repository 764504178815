import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API } from 'aws-amplify';

const FILTERS = ['GOOD', 'BAD', 'UNCERTAIN'];

export const getReports = createAsyncThunk('reports/getReports', async payload => {
  try {
    return await API.get('api', '/reports');
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getReport = createAsyncThunk('reports/getReport', async payload => {
  const { reportId } = payload;
  try {
    return await API.get('api', `/reports/${reportId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const testReport = createAsyncThunk('reports/testReport', async payload => {
  const { reportName, jobIds, userIds, qualities, cadence } = payload;
  try {
    return await API.post('api', '/reports/test', { body: { reportName, jobIds, userIds, qualities, cadence } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const updateReport = createAsyncThunk('reports/updateReport', async payload => {
  const { reportId, reportName, jobIds, userIds, qualities, cadence } = payload;
  try {
    return await API.put('api', `/reports/${reportId}`, { body: { reportName, jobIds, userIds, qualities, cadence } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const createReport = createAsyncThunk('reports/createReport', async (payload, thunkAPI) => {
  const { reportName, jobIds, userIds, qualities, cadence } = payload;
  try {
    return await API.post('api', '/reports', { body: { reportName, jobIds, userIds, qualities, cadence } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const deleteReport = createAsyncThunk('reports/deleteReport', async (payload, thunkAPI) => {
  const { reportId } = payload;
  try {
    return await API.del('api', `/reports/${reportId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    items: {},
    next: {},
  },
  reducers: {
    'updateReport/fulfilled': (state, action) => {
      const { reportId, ...updates } = action.meta.arg;
      const report = state.items[reportId] ?? { reportId };
      const updatedReport = Object.assign({}, report, updates);
      state.items[reportId] = updatedReport;
    },
    'getReports/fulfilled': (state, action) => {
      const { reports } = action.payload;
      const oldItems = state.items;
      const newItems = reports.reduce((newItems, newReport) => {
        const oldReport = oldItems[newReport.reportId] || {};
        newItems[newReport.reportId] = {
          ...newReport,
          ...oldReport,
        };
        return newItems;
      }, {});

      state.items = newItems;
    },
    'getReport/fulfilled': (state, action) => {
      const report = action.payload;
      state.items[report.reportId] = report;
    },
    'deleteReport/fulfilled': (state, action) => {
      const { reportId } = action.meta.arg;
      delete state.items[reportId];
    },
  },
});

const selectItems = (state, props) => state.reports.items;
const selectItem = (state, props) => props.reportId;
const selectFilter = (state, props) => props.filter;
const selectPage = (state, props) => props.page;

export const selectReport = () => {
  return createSelector([selectItems, selectItem], (data, id) => {
    const items = data || {};
    return items[id] || {};
  });
};

export const selectReports = () => {
  return createSelector([selectItems, selectFilter, selectPage], (data, quality, page) => {
    const items = Object.values(data ?? {});
    const qualityFilter = quality ? quality.toUpperCase() : null;
    const filteredItems = FILTERS.includes(qualityFilter)
      ? items.filter(item => item.predictedQuality === qualityFilter)
      : items;

    // pagination
    const total = filteredItems.length;
    const pageNumber = page ?? 1;
    const itemsPerPage = page ? 120 : total;
    const previousPage = pageNumber - 1;
    const pages = Math.ceil(total / itemsPerPage);

    const results = filteredItems.slice(previousPage * itemsPerPage, pageNumber * itemsPerPage);
    return {
      items: results,
      total,
      pages,
      pageNumber,
      itemsPerPage,
    };
  });
};

export default reportsSlice.reducer;
