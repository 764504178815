import React from 'react';
import { Switch } from '@headlessui/react';

import CameraViewer from './CameraViewer';
import classNames from 'utils/classNames';

export const CameraSettings = props => {
  const { cameraId, camera, deviceId, changeHandler, cameraSettings } = props;

  const exposureInfo = camera.props?.['ExposureTime'];
  const gainInfo = camera.props?.['Gain'];

  const changeSlider = ({ target }) => {
    const { name, value } = target;
    const key = name.slice(0, -cameraId.length);
    changeHandler(cameraId, key, parseFloat(value));
  };

  const cameraEnabled = cameraSettings?.enabled ?? true;

  return (
    <div>
      {cameraEnabled && <CameraViewer deviceId={deviceId} cameraId={cameraId} />}
      <div className="space-y-4 sm:space-y-3 px-6 py-4 max-w-7xl w-full">
        <Switch.Group as="div" className="flex items-center justify-between">
          <Switch.Label as="span" className="flex-grow flex flex-col" passive>
            <span className="text-sm font-medium text-gray-900">Enable camera {cameraId}</span>
          </Switch.Label>
          <Switch
            checked={cameraEnabled}
            onChange={() => changeHandler(cameraId, 'enabled', !cameraEnabled)}
            className={classNames(
              cameraEnabled ? 'bg-indigo-800' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                cameraEnabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
        </Switch.Group>

        {cameraEnabled && exposureInfo && (
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
            <label htmlFor={`exposure${cameraId}`} className="block text-sm font-medium sm:mt-px sm:pt-2">
              Exposure: {cameraSettings?.exposure}
            </label>
            <div className="mt-1 sm:mt-2 sm:col-span-2">
              <input
                id={`exposure${cameraId}`}
                name={`exposure${cameraId}`}
                type="range"
                onChange={e => changeSlider(e)}
                value={cameraSettings?.exposure || exposureInfo.value}
                min={exposureInfo.min}
                max={Math.min(exposureInfo.max, 30000)}
                step={exposureInfo.step || null}
                className="w-full"
              />
            </div>
          </div>
        )}
        {cameraEnabled && gainInfo && (
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start ">
            <label htmlFor={`gain${cameraId}`} className="block text-sm font-medium  sm:mt-px sm:pt-2">
              Gain: {cameraSettings?.gain}
            </label>
            <div className="mt-1 sm:mt-2 sm:col-span-2">
              <input
                id={`gain${cameraId}`}
                name={`gain${cameraId}`}
                type="range"
                onChange={e => changeSlider(e)}
                value={cameraSettings?.gain || gainInfo.value}
                min="0"
                max={gainInfo.max}
                step={gainInfo.step || null}
                className="w-full"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CameraSettings;
