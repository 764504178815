import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SignalIcon, SignalSlashIcon } from '@heroicons/react/24/outline';

import { selectStream, startViewer, stopViewer } from 'state/slices/streamsSlice';

import WebRTCVideo from '../components/WebRTCVideo';

export const CameraViewer = props => {
  const dispatch = useDispatch();
  const { deviceId, cameraId } = props;

  const getSelectedStream = useMemo(selectStream, []);
  const stream = useSelector(state => getSelectedStream(state, { deviceId, cameraId }));
  const { status, clientId } = stream.client || {};

  useEffect(() => {
    if (status === 'TRACK') return;
    dispatch(startViewer({ deviceId, cameraId }));
    return function cleanUp() {
      dispatch(stopViewer({ deviceId, cameraId }));
    };
  }, [deviceId, cameraId]); // eslint-disable-line

  return (
    <div>
      {status === 'TRACK' && <WebRTCVideo width={1080} height={1080} clientId={clientId} />}
      <div className="px-6 max-w-7xl w-full mt-3 relative flex items-center justify-between">
        <div className="flex-grow flex flex-col text-sm font-medium">Live Stream: </div>
        <div className="inline-flex flex-shrink-0 ml-3 flex items-center h-5">
          {status === 'TRACK' ? (
            <SignalIcon className="h-5 w-5 text-gray-900" />
          ) : (
            <SignalSlashIcon className="h-5 w-5 text-gray-400" />
          )}
        </div>
        <div className="ml-3 text-sm">{status || stream.Status || 'OFFLINE'}</div>
      </div>
    </div>
  );
};

export default CameraViewer;
