import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Switch } from '@headlessui/react';
import { useFormik } from 'formik';

import { updateJob } from 'state/slices/jobsSlice';
import { activateDeviceJob, updateComponentState } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';
import Tabs from '../components/Tabs';

import getJobSettingsNav from '../navs/jobSettingsNav';

import classNames from 'utils/classNames';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

export const JobSettings = props => {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const { headerTitle, sectionNav, job, sectionTitle } = props;
  const { message, status, method } = useSelector(state => state.status);

  const { activeJobId, deviceId } = job.device;
  const [isActiveJob, setIsActiveJob] = useState(activeJobId === jobId);

  const formik = useFormik({
    initialValues: {
      jobName: job.jobName,
    },
    validate: values => {
      const errors = {};
      if (!values.jobName) {
        errors.jobName = 'Required';
      }
      return errors;
    },
    onSubmit: values => {
      const { jobName } = values;
      const jobId = job.jobId;
      dispatch(updateJob({ jobId, jobName }));
    },
  });

  const changeMode = isActive => {
    setIsActiveJob(!isActive);
    if (!isActive) {
      dispatch(activateDeviceJob({ deviceId, jobId, mode: 'ACTIVE' }));
    } else {
      dispatch(
        updateComponentState({
          deviceId,
          componentName: 'QualityControl',
          state: { mode: 'READY' },
        })
      );
    }
  };

  return (
    <PrivateTemplate sectionNav={sectionNav} sectionTitle={sectionTitle} headerTitle={`${headerTitle} › Settings`}>
      {job.device.activeJobId !== job.jobId && <WarningBanner text="Job is not active." />}
      <Tabs nav={getJobSettingsNav(jobId, 'settings', job)} />
      {method === 'updateJob' && status === 'rejected' && <WarningBanner text={message} />}
      {method === 'updateJob' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully updated ${job.jobName}`} />
      )}
      <div className="max-w-7xl w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="space-y-4">
          <div className="max-w-2xl">
            <div role="group" aria-labelledby="label-notifications">
              <div className="mt-2 space-y-4">
                <Switch.Group as="div" className="flex items-center justify-between">
                  <Switch.Label as="span" className="flex-grow flex flex-col" passive>
                    <span className="mr-4 text-sm font-medium text-gray-900">Active Job</span>
                  </Switch.Label>
                  <Switch
                    checked={isActiveJob}
                    onChange={() => changeMode(isActiveJob)}
                    className={classNames(
                      isActiveJob ? 'bg-indigo-800' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
                    )}>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isActiveJob ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-t border-b border-gray-200 max-w-7xl w-full mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="space-y-4">
          <form onSubmit={formik.handleSubmit} className="max-w-2xl mt-6">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              <div className="sm:col-span-6">
                <label htmlFor="jobId" className="block text-sm font-medium text-gray-900">
                  Job Id
                </label>
                <input
                  type="text"
                  readOnly
                  name="jobId"
                  id="jobId"
                  value={job.jobId}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-200 text-gray-700 sm:text-sm focus:ring-indigo-300 focus:border-indigo-200"
                />
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="deviceName" className="block text-sm font-medium text-gray-900">
                  Station Name
                </label>
                <input
                  type="text"
                  readOnly
                  name="deviceName"
                  id="deviceName"
                  value={job.device.deviceName}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-200 text-gray-700 sm:text-sm focus:ring-indigo-300 focus:border-indigo-200"
                />
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="deviceId" className="block text-sm font-medium text-gray-900">
                  Station Id
                </label>
                <input
                  type="text"
                  readOnly
                  name="deviceId"
                  id="deviceId"
                  value={job.device.deviceId}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm bg-gray-200 text-gray-700 sm:text-sm focus:ring-indigo-300 focus:border-indigo-200"
                />
              </div>
              <div className="sm:col-span-6">
                {formik.errors.jobName && formik.touched.jobName && <Error>{formik.errors.jobName}</Error>}
                <label htmlFor="jobName" className="block text-sm font-medium text-gray-900">
                  Job Name
                </label>
                <input
                  type="text"
                  name="jobName"
                  id="jobName"
                  onChange={formik.handleChange}
                  value={formik.values.jobName}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-gray-900 sm:text-sm focus:ring-indigo-700 focus:border-indigo-500"
                />
              </div>
            </div>
            <div className="pt-8 flex justify-end">
              <button
                type="submit"
                disabled={status === 'pending'}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default JobSettings;
