import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import { MagnifyingGlassIcon, ChartBarSquareIcon, PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

import { getReports, selectReports, selectReport, deleteReport } from 'state/slices/reportsSlice';
import { getUsers } from 'state/slices/usersSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import Pagination from '../components/Pagination';
import EmptyState from '../components/EmptyState';
import WarningDialog from '../components/WarningDialog';

export const Reports = () => {
  const { filter, pageNumber } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useSelector(state => state.status);

  const page = parseInt(pageNumber) || 1;
  const getSelectedReports = useMemo(selectReports, []);
  const pageData = useSelector(state => getSelectedReports(state, { filter, page }));
  const users = useSelector(state => state.users.items);
  const [showWarning, setShowWarning] = useState(false);
  const [reportId, setReportId] = useState(null);

  const getSelectedReport = useMemo(selectReport, []);
  const { reportName } = useSelector(state => getSelectedReport(state, { reportId }));

  useEffect(() => {
    dispatch(getReports());
    dispatch(getUsers());
  }, []); // eslint-disable-line

  const deleteReportHandler = () => {
    dispatch(deleteReport({ reportId }));
    setShowWarning(false);
  };

  const warningHandler = id => {
    setReportId(id);
    setShowWarning(true);
  };

  const filterHandler = e => {
    const filter = encodeURIComponent(e.target.value);
    history.push(`/reports/${page}/${filter}`);
  };

  const headerActions = (
    <div className="sm:flex-1 sm:flex items-center justify-between">
      <Link to="/reports/add" className="mr-8 flex-1 flex items-center ">
        <PlusCircleIcon className="mr-2 h-5 w-5 hover:text-indigo-900 text-indigo-400" aria-hidden="true" />
        <span className="text-gray-900 hover:underline">Add Report</span>
      </Link>

      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="search"
          className="focus:ring-indigo-700 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search"
          defaultValue={filter}
          onChange={filterHandler}
        />
      </div>
    </div>
  );

  return (
    <PrivateTemplate headerTitle="Reports" headerActions={headerActions}>
      <WarningDialog
        title={`Final check: ${reportName}`}
        text="Deleting cannot be undone. Click continue to confirm."
        confirmHandler={deleteReportHandler}
        cancelHandler={() => setShowWarning(false)}
        isOpen={showWarning}
      />
      <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          {pageData.items.length === 0 ? (
            <EmptyState
              title="No Reports"
              description="Get started by adding a new report."
              cta="Add Report"
              to="/reports/add"
            />
          ) : (
            <div className="overflow-scroll border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Report
                    </th>
                    <th
                      scope="col"
                      className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cadence
                    </th>
                    <th
                      scope="col"
                      className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Products
                    </th>
                    <th scope="col" className="relative px-2 lg:px-6 py-3">
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {pageData.items.map(report => (
                    <tr key={report.reportId}>
                      <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                        <Link to={`/report/${report.reportId}`}>
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-8 w-8">
                              <ChartBarSquareIcon className="h-6 w-6 text-gray-400" />
                            </div>
                            <div className="ml-2 lg:ml-4">
                              <div className="text-sm font-medium ">{report.reportName}</div>
                              <div className="text-sm text-gray-500">
                                {report.userIds
                                  .map(userId => {
                                    const user = users[userId] ?? {};
                                    return `${user.givenName} ${user.familyName}`;
                                  })
                                  .join(', ')}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </td>

                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {report.cadence}
                        </span>
                      </td>

                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        <Link to={`/report/${report.reportId}`}>
                          <div className="text-sm">{report.qualities.join(', ')}</div>
                        </Link>
                      </td>
                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap align-middle text-right text-sm font-medium">
                        <button
                          disabled={status === 'pending'}
                          type="submit"
                          onClick={() => warningHandler(report.reportId)}>
                          <XCircleIcon
                            className="h-8 w-8 inline-flex align-middle text-gray-400 hover:text-red-600"
                            aria-hidden="true"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pageData={pageData} baseUrl={`/reports/${filter}`} />
            </div>
          )}
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Reports;
