/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Switch } from '@headlessui/react';

import classNames from 'utils/classNames';

export const LabelToggle = ({ changeHandler, value, enabled, label, description }) => {
  return (
    <Switch.Group as="div" className="flex w-full cursor-pointer items-center">
      <Switch.Label as="span" className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between mr-3">
        <span className="text-sm font-medium ">{label ?? value}</span>
        {description && <span className="text-sm text-gray-500">{description}</span>}
      </Switch.Label>
      <Switch
        checked={enabled}
        onChange={() => changeHandler(value)}
        className={classNames(
          enabled ? 'bg-indigo-800' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full  transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
        )}>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

export default LabelToggle;
