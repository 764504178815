import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import humanizeDuration from 'humanize-duration';

import { StarIcon } from '@heroicons/react/24/solid';

import { getModels, selectModels, activateModel, trainModel } from 'state/slices/modelsSlice';
import { getJob } from 'state/slices/jobsSlice';

import Pagination from '../components/Pagination';
import PrivateTemplate from '../components/PrivateTemplate';
import EmptyState from '../components/EmptyState';
import SuccessBanner from '../components/SuccessBanner';
import WarningBanner from '../components/WarningBanner';

import useInterval from 'utils/useInterval';
import classNames from 'utils/classNames';
import formatTimestamp from 'utils/formatTimestamp';

const statusColorMap = {
  InProgress: 'bg-yellow-100 text-yellow-800',
  Active: 'bg-green-800 text-green-100',
  Completed: 'bg-green-100 text-green-800',
  Failed: 'bg-red-100 text-red-800',
  Stopping: 'bg-orange-100 text-orange-800',
  Stopped: 'bg-orange-100 text-orange-800',
};

export const Models = props => {
  const dispatch = useDispatch();

  const { jobId } = useParams();
  const { sectionNav, headerTitle, sectionTitle, job } = props;
  const { activeModelId } = job;

  const getSelectedModels = useMemo(selectModels, []);
  const pageData = useSelector(state => getSelectedModels(state, { jobId }));
  const { message, status, args, method } = useSelector(state => state.status);

  useEffect(() => {
    dispatch(getModels({ jobId }));
  }, [jobId]); // eslint-disable-line

  const activateModelHandler = modelId => {
    dispatch(activateModel({ jobId, modelId }));
  };

  const pollingInterval = 60 * 1000;
  useInterval(
    () => {
      dispatch(getModels({ jobId }));
      dispatch(getJob({ jobId }));
    },
    pollingInterval,
    [jobId]
  );

  const trainModelHandler = () => {
    if (job.trainingInProgress) return;
    dispatch(trainModel({ jobId }));
  };

  const headerActions = (
    <div className="flex-1 flex items-center justify-between">
      <button
        disabled={job.trainingInProgress}
        onClick={trainModelHandler}
        className="inline-flex justify-center py-2 px-4 shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed">
        {job.trainingInProgress ? 'In Progress' : 'Create Model'}
      </button>
    </div>
  );

  return (
    <PrivateTemplate
      headerTitle={`${headerTitle} › Models`}
      headerActions={headerActions}
      sectionNav={sectionNav}
      sectionTitle={sectionTitle}>
      {job.device.activeJobId !== job.jobId && <WarningBanner text="Job is not active." />}

      {method === 'trainModel' && status === 'fulfilled' && (
        <SuccessBanner text="Successfully started model training" />
      )}
      {method === 'activateModel' && status === 'fulfilled' && args.jobId === jobId && (
        <SuccessBanner text="Successfully updated model" to={`/job/${jobId}/products`} />
      )}
      {status === 'rejected' && <WarningBanner text={message} />}
      {pageData.total === 0 ? (
        <EmptyState title="No Models" description="Please create your first model" />
      ) : (
        <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="overflow-scroll border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Model
                    </th>

                    <th
                      scope="col"
                      className="hidden xl:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Duration
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Rating
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Deploy</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {pageData.items.map(model => (
                    <tr key={model.modelId}>
                      <td className="px-6 lg:px-8 py-4 whitespace-nowrap h-20">
                        <span
                          className={classNames(
                            model.modelId === activeModelId ? statusColorMap['Active'] : statusColorMap[model.status],
                            'px-2 inline-flex text-xs leading-5 font-semibold rounded-full'
                          )}>
                          {model.modelId === activeModelId ? 'Active' : model.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="text-sm font-medium">{formatTimestamp(new Date(model.createdAt))}</div>
                        </div>
                      </td>
                      <td className="hidden xl:table-cell px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium">{humanizeDuration(model.duration * 1000)}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap h-20">
                        {model.status === 'Completed' && model.metrics && (
                          <Link to={`/job/${jobId}/model/${model.modelId}`}>
                            <div className="flex items-center">
                              <div className="flex items-center">
                                {[0.0, 0.5, 0.7, 0.8, 0.9].map(rating => (
                                  <StarIcon
                                    key={rating}
                                    className={classNames(
                                      model.metrics.score_quality >= rating ? 'text-yellow-400' : 'text-gray-200',
                                      'h-5 w-5 flex-shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                ))}
                              </div>
                              <p className="sr-only">{model.metrics.score_quality * 100} out of 100</p>
                            </div>
                          </Link>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium h-20">
                        {model.status === 'Completed' && (
                          <Switch.Group as="div" className="flex items-center justify-between">
                            <Switch
                              checked={model.modelId === activeModelId}
                              onChange={() => activateModelHandler(model.modelId)}
                              className={classNames(
                                model.modelId === activeModelId ? 'bg-indigo-800' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
                              )}>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  model.modelId === activeModelId ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                )}
                              />
                            </Switch>
                          </Switch.Group>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pageData={pageData} baseUrl={`/job/${jobId}/models`} />
            </div>
          </div>
        </div>
      )}
    </PrivateTemplate>
  );
};

export default Models;
