import AWS from 'aws-sdk';
import 'webrtc-adapter'; // adds global shim so our viewer works in safari/webkit

import { nanoid } from 'nanoid';
import { SignalingClient, Role } from 'amazon-kinesis-video-streams-webrtc';

const WS_PING_PONG_INTERVAL_MS = 5 * 60 * 1000; // 5 minutes

export default class WebRTCViewer {
  static viewers = {};
  static streams = {};

  constructor(credentials, channelName, region, debug = false) {
    this.credentials = credentials;
    this.channelName = channelName;
    this.region = region;
    this.debug = debug;

    this.wsPingPongIntervalId = null;

    this.onSignalingClientOpen = this.onSignalingClientOpen.bind(this);
    this.onSignalingClientClose = this.onSignalingClientClose.bind(this);
    this.onSignalingClientMessage = this.onSignalingClientMessage.bind(this);
    this.onSignalingClientSdpAnswer = this.onSignalingClientSdpAnswer.bind(this);
    this.onSignalingClientIceCandidate = this.onSignalingClientIceCandidate.bind(this);
    this.onSignalingClientError = this.onSignalingClientError.bind(this);

    this.onPeerConnectionStateChange = this.onPeerConnectionStateChange.bind(this);
    this.onPeerConnectionNegotiationNeeded = this.onPeerConnectionNegotiationNeeded.bind(this);
    this.onPeerConnectionSignalingStateChange = this.onPeerConnectionSignalingStateChange.bind(this);
    this.onPeerConnectionIceConnectionStateChange = this.onPeerConnectionIceConnectionStateChange.bind(this);
    this.onPeerConnectionIceGatheringStateChange = this.onPeerConnectionIceGatheringStateChange.bind(this);
    this.onPeerConnectionIceCandidate = this.onPeerConnectionIceCandidate.bind(this);
    this.onPeerConnectionIceCandidateError = this.onPeerConnectionIceCandidateError.bind(this);
    this.onPeerConnectionTrack = this.onPeerConnectionTrack.bind(this);

    this.sendPing = this.sendPing.bind(this);
  }

  async onSignalingClientOpen() {
    if (this.debug) {
      console.debug('[WebRTCViewer] onSignalingClientOpen');
    }

    // XXX: temporary: see https://github.com/awslabs/amazon-kinesis-video-streams-webrtc-sdk-js/pull/83
    this.wsPingPongIntervalId = window.setInterval(this.sendPing, WS_PING_PONG_INTERVAL_MS);

    // create an sdp offer and send it to the master
    const offer = await this.peerConnection.createOffer({
      offerToReceiveVideo: true,
      offerToReceiveAudio: false,
    });
    await this.peerConnection.setLocalDescription(offer);

    if (this.debug) {
      console.debug('[WebRTCViewer] signalingClient.sendSdpOffer:', this.peerConnection.localDescription);
    }
    this.signalingClient.sendSdpOffer(this.peerConnection.localDescription);
  }

  async onSignalingClientClose() {
    if (this.debug) {
      console.debug('[WebRTCViewer] onSignalingClientClose');
    }
    if (this.wsPingPongIntervalId) {
      window.clearInterval(this.wsPingPongIntervalId);
      this.wsPingPongIntervalId = null;
    }
  }

  onSignalingClientMessage(event) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onSignalingClientMessage:', event);
    }
  }

  async onSignalingClientSdpAnswer(answer) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onSignalingClientSdpAnswer:', answer);
    }
    // when the sdp answer is received back from the master, add it to the peer connection
    await this.peerConnection.setRemoteDescription(answer);
  }

  async onSignalingClientIceCandidate(candidate) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onSignalingClientIceCandidate:', candidate.candidate);
    }
    // when an ice candidate is received from the master, add it to the peer connection
    await this.peerConnection.addIceCandidate(candidate);
  }

  onSignalingClientError(err) {
    if (this.debug) {
      console.error('[WebRTCViewer] onSignalingClientError:', err);
    }
  }

  onPeerConnectionStateChange(event) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onPeerConnectionStateChange:', this.peerConnection.connectionState);
    }
  }

  onPeerConnectionNegotiationNeeded(event) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onPeerConnectionNegotiationNeeded');
    }
  }

  onPeerConnectionSignalingStateChange(event) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onPeerConnectionSignalingStateChange:', this.peerConnection.signalingState);
    }
  }

  onPeerConnectionIceConnectionStateChange(event) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onPeerConnectionIceConnectionStateChange:', this.peerConnection.iceConnectionState);
    }
  }

  onPeerConnectionIceGatheringStateChange(event) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onPeerConnectionIceGatheringStateChange:', this.peerConnection.iceGatheringState);
    }
  }

  async onPeerConnectionIceCandidate({ candidate }) {
    if (!candidate) {
      if (this.debug) {
        console.debug('[WebRTCViewer] onPeerConnectionIceCandidate: all ICE candidates have been generated');
      }
      // no more ice candidates will be generated
      return;
    }

    if (this.debug) {
      console.debug('[WebRTCViewer] onPeerConnectionIceCandidate: generated ICE candidate:', candidate.candidate);
    }

    // when trickle ice is enabled, send the ice candidates as they are generated
    this.signalingClient.sendIceCandidate(candidate);
  }

  async onPeerConnectionIceCandidateError({ errorCode, errorText, address, port, url }) {
    if (this.debug) {
      console.warn('[WebRTCViewer] onPeerConnectionIceCandidateError:', errorCode, errorText, address, port, url);
    }
  }

  async onPeerConnectionTrack({ streams }) {
    if (this.debug) {
      console.debug('[WebRTCViewer] onPeerConnectionTrack:', streams);
    }
    WebRTCViewer.streams[this.clientId] = streams[0];
  }

  sendPing() {
    if (this.debug) {
      console.debug('[WebRTCViewer] sendPing');
    }
    this.signalingClient.sendMessage('PING', {});
  }

  async init() {
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: this.region,
      credentials: this.credentials,
      correctClockSkew: true,
    });

    // get signaling channel arn
    const describeSignalingChannel = await kinesisVideoClient
      .describeSignalingChannel({ ChannelName: this.channelName })
      .promise();
    const channelARN = describeSignalingChannel.ChannelInfo.ChannelARN;

    // get signaling channel endpoints
    const getSignalingChannelEndpoint = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: Role.VIEWER,
        },
      })
      .promise();

    const endpointsByProtocol = getSignalingChannelEndpoint.ResourceEndpointList.reduce((endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      return endpoints;
    }, {});

    if (this.debug) {
      console.debug('[WebRTCViewer] endpointsByProtocol:', endpointsByProtocol);
    }

    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
      region: this.region,
      credentials: this.credentials,
      endpoint: endpointsByProtocol.HTTPS,
      correctClockSkew: true,
    });

    // get ice server configuration
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();

    const iceServers = [{ urls: `stun:stun.kinesisvideo.${this.region}.amazonaws.com:443` }];

    getIceServerConfigResponse.IceServerList.forEach(iceServer =>
      iceServers.push({
        urls: iceServer.Uris,
        username: iceServer.Username,
        credential: iceServer.Password,
      })
    );

    if (this.debug) {
      console.debug('[WebRTCViewer] iceServers:', iceServers);
    }

    this.peerConnection = new RTCPeerConnection({ iceServers });
    if (this.debug) {
      console.debug('[WebRTCViewer] peerConnection:', this.peerConnection);
    }

    this.clientId = nanoid();

    this.signalingClient = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId: this.clientId,
      role: Role.VIEWER,
      region: this.region,
      credentials: this.credentials,
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });
    if (this.debug) {
      console.debug('[WebRTCViewer] signalingClient:', this.signalingClient);
    }

    this.signalingClient.on('open', this.onSignalingClientOpen);
    this.signalingClient.on('close', this.onSignalingClientClose);
    this.signalingClient.on('message', this.onSignalingClientMessage);
    this.signalingClient.on('sdpAnswer', this.onSignalingClientSdpAnswer);
    this.signalingClient.on('iceCandidate', this.onSignalingClientIceCandidate);
    this.signalingClient.on('error', this.onSignalingClientError);

    // send any ICE candidates to the other peer
    this.peerConnection.addEventListener('connectionstatechange', this.onPeerConnectionStateChange);
    this.peerConnection.addEventListener('negotiationneeded', this.onPeerConnectionNegotiationNeeded);
    this.peerConnection.addEventListener('signalingstatechange', this.onPeerConnectionSignalingStateChange);
    this.peerConnection.addEventListener('iceconnectionstatechange', this.onPeerConnectionIceConnectionStateChange);
    this.peerConnection.addEventListener('icegatheringstatechange', this.onPeerConnectionIceGatheringStateChange);
    this.peerConnection.addEventListener('icecandidate', this.onPeerConnectionIceCandidate);
    this.peerConnection.addEventListener('icecandidateerror', this.onPeerConnectionIceCandidateError);
    this.peerConnection.addEventListener('track', this.onPeerConnectionTrack);
  }

  open() {
    if (this.debug) {
      console.debug('[WebRTCViewer] open');
    }
    this.signalingClient.open();
  }

  close() {
    if (this.debug) {
      console.debug('[WebRTCViewer] close');
    }

    if (this.wsPingPongIntervalId) {
      window.clearInterval(this.wsPingPongIntervalId);
      this.wsPingPongIntervalId = null;
    }

    if (this.signalingClient) {
      this.signalingClient.close();
      this.signalingClient.removeListener('open', this.onSignalingClientOpen);
      this.signalingClient.removeListener('close', this.onSignalingClientClose);
      this.signalingClient.removeListener('message', this.onSignalingClientMessage);
      this.signalingClient.removeListener('sdpAnswer', this.onSignalingClientSdpAnswer);
      this.signalingClient.removeListener('iceCandidate', this.onSignalingClientIceCandidate);
      this.signalingClient.removeListener('error', this.onSignalingClientError);
      this.signalingClient = null;
    }

    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection.removeEventListener('connectionstatechange', this.onPeerConnectionStateChange);
      this.peerConnection.removeEventListener('negotiationneeded', this.onPeerConnectionNegotiationNeeded);
      this.peerConnection.removeEventListener('signalingstatechange', this.onPeerConnectionSignalingStateChange);
      this.peerConnection.removeEventListener(
        'iceconnectionstatechange',
        this.onPeerConnectionIceConnectionStateChange
      );
      this.peerConnection.removeEventListener('icegatheringstatechange', this.onPeerConnectionIceGatheringStateChange);
      this.peerConnection.removeEventListener('icecandidate', this.onPeerConnectionIceCandidate);
      this.peerConnection.removeEventListener('icecandidateerror', this.onPeerConnectionIceCandidateError);
      this.peerConnection.removeEventListener('track', this.onPeerConnectionTrack);
      this.peerConnection = null;
    }

    const remoteStream = WebRTCViewer.streams[this.clientId];
    if (remoteStream) {
      remoteStream.getTracks().forEach(track => track.stop());
      WebRTCViewer.streams[this.clientId] = null;
      delete WebRTCViewer.streams[this.clientId];
    }
  }
}
