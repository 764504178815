import {
  WrenchScrewdriverIcon,
  ClipboardDocumentCheckIcon,
  CameraIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/outline';

export const getDeviceNav = (deviceId, currentSection) => {
  const current = currentSection || 'jobs';
  return [
    {
      name: 'Jobs',
      to: `/station/${deviceId}/jobs`,
      icon: ClipboardDocumentCheckIcon,
      current: current === 'jobs',
    },
    {
      name: 'Cameras',
      to: `/station/${deviceId}/cameras`,
      icon: CameraIcon,
      current: current === 'cameras',
    },
    {
      name: 'Calibration',
      to: `/station/${deviceId}/calibration`,
      icon: ArrowsPointingOutIcon,
      current: current === 'calibration',
    },
    {
      name: 'Settings',
      to: `/station/${deviceId}/settings`,
      icon: WrenchScrewdriverIcon,
      current: current === 'settings',
    },
  ];
};

export default getDeviceNav;
