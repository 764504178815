import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateJob } from 'state/slices/jobsSlice';

import { scaleBoundingBox, getHandles, getActiveBoxIndex, getActiveHandle } from 'utils/interestRegionEditor';
import Canvas from '../components/Canvas';

const debug = false;

const coordsToBoxes = coords => {
  return coords.map(([startY, endY, startX, endX]) => {
    return {
      x: startX,
      y: startY,
      width: endX - startX,
      height: endY - startY,
    };
  });
};

const boxesToCoords = boxes => {
  return boxes.map(({ x, y, width, height }) => {
    const startX = Math.round(x);
    const startY = Math.round(y);
    const endX = Math.round(width + x);
    const endY = Math.round(height + y);
    return [startY, endY, startX, endX];
  });
};

export const InterestRegionEditor = ({ job, src, cameraId, options = {} }) => {
  const dispatch = useDispatch();
  const jobId = job.jobId;
  const defaultRegions = job.interestRegions ?? {};
  const defaultCoords = defaultRegions?.[cameraId] ?? [[0, 1080, 0, 1440]];
  const defaultBoxes = coordsToBoxes(defaultCoords);
  const [currentBoxes, setCurrentBoxes] = useState(defaultBoxes);

  const defaults = {
    key: cameraId,
    boundingBoxes: defaultBoxes,
    animate: true,
    color: '#3b82f6',
    edgeColor: 'white',
    strokeOffset: 3,
    padding: 4,
    handleSize: 8,
    handlePadding: 4,
    minBoxSize: 32,
    doubleTapInterval: 250, // ms
    activeBoxIndex: -1,
    mode: null,
  };

  const canvasOptions = Object.assign(defaults, options);

  const drawHandler = (context, options, time) => {
    const { boundingBoxes, scale, color, edgeColor, strokeOffset, activeBoxIndex, mode } = options;
    const canvas = context.canvas;
    context.clearRect(0, 0, canvas.width, canvas.height);

    boundingBoxes.forEach((boundingBox, i) => {
      const box = scaleBoundingBox(boundingBox, scale);
      const { x, y, width, height } = box;

      const isActive = activeBoxIndex === i && mode !== 'select';

      // draw bounding box
      context.strokeStyle = 'white';
      context.setLineDash([strokeOffset, strokeOffset]);
      context.lineWidth = 1;
      context.lineDashOffset = 0;
      context.lineDashOffset = isActive ? time / 100 : 0;
      context.strokeRect(x, y, width, height);

      context.strokeStyle = 'black';
      context.setLineDash([strokeOffset, strokeOffset]);
      context.lineWidth = 1;
      context.lineDashOffset = (isActive ? time / 100 : 0) + strokeOffset;
      context.strokeRect(x, y, width, height);

      if (isActive) {
        const handles = getHandles(box, options);
        handles.forEach(({ x, y, width, height }) => {
          context.beginPath();
          context.arc(x + width / 2, y + height / 2, width / 2, 0, 2 * Math.PI, false);
          context.setLineDash([]);
          context.lineDashOffset = 0;
          context.lineWidth = 2;
          context.strokeStyle = edgeColor;
          context.stroke();
          context.fillStyle = color;
          context.fill();
        });
      }
    });
  };

  const mouseDownHandler = (context, options, e) => {
    if (debug) {
      console.debug('onMouseDown:', e, context, options);
    }

    const now = Date.now();
    const { scale, boundingBoxes, previousTime, doubleTapInterval } = options;
    const canvas = context.canvas;
    const activeBoxIndex = getActiveBoxIndex(options, e);

    options.mousedown = true;

    if (activeBoxIndex === -1) {
      const newBox = {
        x: e.offsetX / scale.x,
        y: e.offsetY / scale.y,
        width: 0,
        height: 0,
      };
      boundingBoxes.push(newBox);

      options.boundingBoxes = boundingBoxes;
      options.activeBoxIndex = boundingBoxes.length - 1;
      options.mode = 'create';
    } else if (previousTime && now - previousTime < doubleTapInterval) {
      delete options.activeBoxIndex;
      delete options.previousTime;
      delete options.activeHandle;

      boundingBoxes.splice(activeBoxIndex, 1);
      options.boundingBoxes = boundingBoxes;
      options.mode = 'delete';
    } else {
      const boundingBox = boundingBoxes[activeBoxIndex];
      const handle = getActiveHandle(boundingBox, options, e);

      if (handle) {
        options.mode = 'resize';
        options.activeHandle = handle;
        canvas.style.cursor = handle.cursor;
      } else {
        options.mode = 'select';
        options.previousTime = now;
        canvas.style.cursor = 'grabbing';
      }
      options.activeBoxIndex = activeBoxIndex;
    }
  };

  const mouseMoveHandler = (context, options, e) => {
    if (debug) {
      console.debug('onMouseMove:', e, context, options);
    }
    const { mode, boundingBoxes, activeHandle, activeBoxIndex, scale, mousedown, previousCoords } = options;
    const canvas = context.canvas;

    if (!mousedown) {
      let cursor = 'crosshair';
      const boxIndex = getActiveBoxIndex(options, e);

      if (boxIndex !== -1) {
        const boundingBox = boundingBoxes[boxIndex];
        const handle = getActiveHandle(boundingBox, options, e);
        cursor = handle?.cursor ?? 'grab';
      }
      canvas.style.cursor = cursor;

      options.activeBoxIndex = boxIndex;
      options.mode = null;
      return;
    }

    if (activeBoxIndex === -1) {
      return;
    }

    const boundingBox = boundingBoxes[activeBoxIndex];

    if (mode === 'create') {
      boundingBox.width = e.offsetX / scale.x - boundingBox.x;
      boundingBox.height = e.offsetY / scale.y - boundingBox.y;
    }

    if (mode === 'select') {
      const coords = previousCoords || { x: e.offsetX, y: e.offsetY };
      const deltaX = e.offsetX - coords.x;
      const deltaY = e.offsetY - coords.y;
      const offsetX = e.offsetX / scale.x - boundingBox.x;
      const offsetY = e.offsetY / scale.y - boundingBox.y;
      const xNew = (e.offsetX + deltaX) / scale.x - offsetX;
      const yNew = (e.offsetY + deltaY) / scale.y - offsetY;
      const xMax = (canvas.width / (scale.x * window.devicePixelRatio)) - boundingBox.width;
      const yMax = (canvas.height / (scale.y * window.devicePixelRatio)) - boundingBox.height;
      boundingBox.x = Math.min(Math.max(xNew, 0), xMax);
      boundingBox.y = Math.min(Math.max(yNew, 0), yMax);
      options.previousCoords = { x: e.offsetX, y: e.offsetY };
    }

    if (mode === 'resize') {
      canvas.style.cursor = activeHandle.cursor;

      const { x: prevX, y: prevY } = boundingBox;
      const offsetXScaled = e.offsetX / scale.x;
      const offsetYScaled = e.offsetY / scale.y;

      switch (activeHandle.type) {
        case 'leftEdge':
          boundingBox.x = e.offsetX / scale.x;
          boundingBox.width += prevX - boundingBox.x;
          break;
        case 'rightEdge':
          boundingBox.width = offsetXScaled - prevX;
          break;
        case 'topEdge':
          boundingBox.y = e.offsetY / scale.y;
          boundingBox.height += prevY - boundingBox.y;
          break;
        case 'bottomEdge':
          boundingBox.height = offsetYScaled - prevY;
          break;
        case 'topLeftCorner':
          boundingBox.x = e.offsetX / scale.x;
          boundingBox.y = e.offsetY / scale.y;
          boundingBox.width += prevX - boundingBox.x;
          boundingBox.height += prevY - boundingBox.y;
          break;
        case 'topRightCorner':
          boundingBox.y = e.offsetY / scale.y;
          boundingBox.width = offsetXScaled - prevX;
          boundingBox.height += prevY - boundingBox.y;
          break;
        case 'bottomLeftCorner':
          boundingBox.x = e.offsetX / scale.x;
          boundingBox.width += prevX - boundingBox.x;
          boundingBox.height = offsetYScaled - prevY;
          break;
        default:
          boundingBox.width = offsetXScaled - prevX;
          boundingBox.height = offsetYScaled - prevY;
          break;
      }
      options.boundingBoxes[options.activeBoxIndex] = boundingBox;
    }
  };

  const mouseUpHandler = (context, options, e) => {
    if (debug) {
      console.debug('onMouseUp:', e, context, options);
    }

    const { minBoxSize, boundingBoxes, scale, mode, activeBoxIndex } = options;
    const canvas = context.canvas;

    if (mode === 'create' || mode === 'resize') {
      const boundingBox = boundingBoxes[activeBoxIndex];

      if (boundingBox.width < 0) {
        boundingBox.x += boundingBox.width;
        boundingBox.width = Math.abs(boundingBox.width);
      }
      if (boundingBox.height < 0) {
        boundingBox.y += boundingBox.height;
        boundingBox.height = Math.abs(boundingBox.height);
      }
      if (boundingBox.width * scale.x < minBoxSize || boundingBox.height * scale.y < minBoxSize) {
        options.boundingBoxes.splice(activeBoxIndex, 1);
      }
    } else if (mode === 'select' && activeBoxIndex !== -1) {
      canvas.style.cursor = 'grab';
    }

    delete options.activeBoxIndex;
    delete options.activeHandle;
    delete options.mousedown;
    delete options.mode;
    delete options.previousCoords;

    const newBoxes = options.boundingBoxes;
    setCurrentBoxes(newBoxes);
  };

  const eventHandlers = [
    ['mousedown', mouseDownHandler],
    ['mousemove', mouseMoveHandler],
    ['mouseup', mouseUpHandler],
  ];

  const handleSave = () => {
    const boxCoords = boxesToCoords(currentBoxes);
    const interestRegions = Object.assign({}, defaultRegions, { [cameraId]: boxCoords });
    dispatch(updateJob({ jobId, interestRegions }));
  };

  return (
    <div>
      <div className="bg-contain bg-no-repeat" style={{ backgroundImage: `url(${src})` }}>
        <Canvas draw={drawHandler} events={eventHandlers} options={canvasOptions} />
      </div>
      <div className="border-b border-gray-200 flex px-6 py-4 w-full justify-end">
        <button
          type="submit"
          onClick={handleSave}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default InterestRegionEditor;
