import { useState } from 'react';
import { ArrowsPointingOutIcon, ArrowsPointingInIcon } from '@heroicons/react/24/solid';

export const ImageZoom = ({ children, image }) => {
  const [style, setStyle] = useState();

  const moveImage = (e, image) => {
    const { clientWidth, clientHeight } = e.target;
    const { offsetX, offsetY } = e.nativeEvent;

    const percentX = (offsetX / clientWidth) * 100;
    const percentY = (offsetY / clientHeight) * 100;

    setStyle({
      backgroundImage: `url(${image})`,
      backgroundPositionX: `${percentX}%`,
      backgroundPositionY: `${percentY}%`,
    });
  };
  return (
    <div className="relative group" onMouseMove={e => moveImage(e, image)} onMouseLeave={e => setStyle({})}>
      {children}
      <div className="bg-auto top-0 absolute w-full h-full" style={style} />
      <span className="p-1 rounded-md bg-black/60 flex absolute bottom-1 left-1 z-50 text-gray-50 ">
        <ArrowsPointingOutIcon className="group-hover:hidden h-6 w-6" />
        <ArrowsPointingInIcon className="hidden group-hover:block h-6 w-6" />
      </span>
    </div>
  );
};

export default ImageZoom;
