import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API } from 'aws-amplify';

const pause = ms => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const createJob = createAsyncThunk('jobs/createJob', async (payload, thunkAPI) => {
  const { jobName, deviceId } = payload;
  try {
    return await API.post('api', '/jobs', { body: { jobName, deviceId } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getJob = createAsyncThunk('jobs/getJob', async (payload, thunkAPI) => {
  const { jobId, throttle } = payload;
  if (throttle) {
    await pause(throttle);
  }

  try {
    return await API.get('api', `/jobs/${jobId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getJobs = createAsyncThunk('jobs/getJobs', async (payload, thunkAPI) => {
  try {
    return await API.get('api', '/jobs');
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const updateJob = createAsyncThunk('jobs/updateJob', async (payload, thunkAPI) => {
  const { jobId, jobName, cameraSettings, interestRegions, targetMeasurements, tags } = payload;
  try {
    return await API.put('api', `/jobs/${jobId}`, {
      body: { jobName, cameraSettings, interestRegions, targetMeasurements, tags },
    });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const deleteJob = createAsyncThunk('jobs/deleteJob', async (payload, thunkAPI) => {
  const { jobId } = payload;
  try {
    return await API.del('api', `/jobs/${jobId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    items: {},
    latestJobId: null,
  },
  reducers: {
    'updateJob/fulfilled': (state, action) => {
      const { jobId, ...updates } = action.meta.arg;
      const job = state.items[jobId] ?? { jobId };
      const updatedJob = Object.assign({}, job, updates);
      state.items[jobId] = updatedJob;
    },
    'createJob/fulfilled': (state, action) => {
      const { jobId } = action.payload;
      state.latestJobId = jobId;
    },
    'createJob/pending': (state, action) => {
      state.latestJobId = null;
    },
    'getJob/fulfilled': (state, action) => {
      const { payload } = action;
      state.updatedAt = Date.now();
      state.items[payload.jobId] = payload;
    },
    'deleteJob/fulfilled': (state, action) => {
      const { jobId } = action.meta.arg;
      delete state.items[jobId];
    },
    'getJobs/fulfilled': (state, action) => {
      const { jobs } = action.payload;
      const oldItems = state.items;
      const newItems = jobs.reduce((newItems, newJob) => {
        const oldJob = oldItems[newJob.jobId] || {};
        newItems[newJob.jobId] = {
          ...newJob,
          ...oldJob,
        };
        return newItems;
      }, {});
      state.items = newItems;
    },
  },
  extraReducers: builder =>
    builder
      .addMatcher(
        action => action.type === 'devices/updateComponentState/fulfilled',
        (state, action) => {
          const { deviceId, ...args } = action.meta.arg;
          const { mode } = args.state;

          for (const itemId in state.items) {
            const job = state.items[itemId];
            if (job.device.deviceId === deviceId) {
              delete job.device.activeJobId;

              const deviceState = Object.assign({ desired: { mode } }, job.device.state);
              deviceState.desired.mode = mode;
              job.device.state = deviceState;

              state.items[itemId] = job;
            }
          }
          state.updatedAt = Date.now();
        }
      )
      .addMatcher(
        action => action.type === 'devices/activateDeviceJob/fulfilled',
        (state, action) => {
          const { deviceId, jobId, mode } = action.meta.arg;
          for (const itemId in state.items) {
            const job = state.items[itemId];
            if (job.device.deviceId === deviceId) {
              job.device.activeJobId = jobId;

              const deviceState = Object.assign({ desired: { mode } }, job.device.state);
              deviceState.desired.mode = mode;
              job.device.state = deviceState;

              state.items[itemId] = job;
            }
          }
          state.updatedAt = Date.now();
        }
      )
      .addMatcher(
        action => action.type === 'models/activateModel/fulfilled',
        (state, action) => {
          const { modelId, jobId } = action.meta.arg;
          const item = state.items[jobId] || {};
          item.activeModelId = modelId;
          state.items[jobId] = item;
        }
      ),
});

const selectItems = (state, props) => state.jobs.items;
const selectItem = (state, props) => props.jobId;
const selectFilter = (state, props) => props.filter;
const selectPage = (state, props) => props.page;

export const selectJob = () => {
  return createSelector([selectItems, selectItem], (data, id) => {
    const items = data || {};
    return items[id] || {};
  });
};

export const selectComponentState = () => {
  return createSelector([selectItems, selectItem, selectFilter], (data, id, componentName) => {
    const item = data[id] || {};
    const deviceState = item?.device?.state || {};
    const component = deviceState[componentName] || {};
    const reported = component.state?.reported || {};
    const desired = component.state?.desired || {};
    return { desired, reported };
  });
};

export const selectAllJobs = () => {
  return createSelector([selectItems], items => {
    return Object.values(items).map(job => {
      return { name: job.jobName, id: job.jobId, status: true };
    });
  });
};

export const selectJobs = () => {
  return createSelector([selectItems, selectFilter, selectPage], (data, filter, page) => {
    const items = Object.values(data || {});
    const search = filter?.toLowerCase();
    const filteredItems = filter
      ? items.filter(item => {
          const success =
            item.jobId.toLowerCase().startsWith(search) ||
            item.jobName.toLowerCase().startsWith(search) ||
            item.device.deviceId.toLowerCase().startsWith(search) ||
            item.device.deviceName.toLowerCase().startsWith(search);
          return success;
        })
      : items;

    // pagination
    const pageNumber = page || 1;
    const itemsPerPage = 24;
    const previousPage = pageNumber - 1;
    const total = filteredItems.length;
    const pages = Math.ceil(total / itemsPerPage);

    const results = filteredItems.slice(previousPage * itemsPerPage, pageNumber * itemsPerPage);
    return {
      items: results,
      total,
      pages,
      pageNumber,
      itemsPerPage,
      filter,
    };
  });
};
export default jobsSlice.reducer;
