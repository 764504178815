import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

const DEGREES_TO_RADIANS = Math.PI / 180;
const RADIANS_TO_DEGREES = 180 / Math.PI;
const EPSILON = 1e-6;

const getSize = (context, options) => {
  const canvas = context.canvas;

  const visualViewport = window.visualViewport;
  const parentEl = canvas.parentElement;
  const parentSize = parentEl.getBoundingClientRect();

  const parentWidth = parentSize.width;
  const parentHeight = visualViewport.height - parentSize.top;
  const parentAspectRatio = parentWidth / parentHeight;

  const { width: optionsWidth, height: optionsHeight } = options;
  const optionsAspectRatio = optionsWidth / optionsHeight;

  let width, height;
  if (optionsAspectRatio < parentAspectRatio) {
    width = (optionsWidth * parentHeight) / optionsHeight;
    height = parentHeight;
  } else {
    height = (optionsHeight * parentWidth) / optionsWidth;
    width = parentWidth;
  }

  return [width, height];
};

const updateProjectionMatrix = (camera, cameraMatrix, w, h) => {
  const fx = cameraMatrix[0][0];
  const fy = cameraMatrix[1][1];
  const cx = cameraMatrix[0][2];
  const cy = cameraMatrix[1][2];
  const zn = 0.01;
  const zf = 10000;
  const A = (w - 2 * cx) / w;
  const B = (h - 2 * cy) / h;
  const C = (-zf - zn) / (zf - zn);
  const D = (-2 * zf * zn) / (zf - zn);
  const projectionMatrix = [(2 * fx) / w, 0, A, 0, 0, (2 * fy) / h, B, 0, 0, 0, C, D, 0, 0, -1, 0];
  camera.projectionMatrix.set(...projectionMatrix);
  camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
};

const createModelViewMatrix = extrinsics => {
  const extrinsicsMatrix = new THREE.Matrix4();

  if (extrinsics) {
    const { translation, rotation } = extrinsics;

    const translationVector = new THREE.Vector3();
    translationVector.set(translation[0][0], translation[1][0], translation[2][0]);

    const rotationMatrix = new THREE.Matrix3();
    rotationMatrix.set(
      rotation[0][0],
      rotation[0][1],
      rotation[0][2],
      rotation[1][0],
      rotation[1][1],
      rotation[1][2],
      rotation[2][0],
      rotation[2][1],
      rotation[2][2]
    );

    extrinsicsMatrix.setFromMatrix3(rotationMatrix);
    extrinsicsMatrix.setPosition(translationVector);
  }

  const correctionMatrix = new THREE.Matrix4();
  correctionMatrix.set(+1, +0, +0, +0, +0, -1, +0, +0, +0, +0, -1, +0, +0, +0, +0, +1);

  extrinsicsMatrix.multiply(correctionMatrix);
  return extrinsicsMatrix;
};

const lineLineIntersect = (p1, p2, p3, p4, pa, pb) => {
  const p13 = new THREE.Vector3();
  const p43 = new THREE.Vector3();
  const p21 = new THREE.Vector3();

  p13.subVectors(p1, p3);
  p43.subVectors(p4, p3);
  p21.subVectors(p2, p1);

  if (Math.abs(p43.x) < EPSILON && Math.abs(p43.y) < EPSILON && Math.abs(p43.z) < EPSILON) {
    return false;
  }

  if (Math.abs(p21.x) < EPSILON && Math.abs(p21.y) < EPSILON && Math.abs(p21.z) < EPSILON) {
    return false;
  }

  const d1343 = p13.x * p43.x + p13.y * p43.y + p13.z * p43.z;
  const d4321 = p43.x * p21.x + p43.y * p21.y + p43.z * p21.z;
  const d1321 = p13.x * p21.x + p13.y * p21.y + p13.z * p21.z;
  const d4343 = p43.x * p43.x + p43.y * p43.y + p43.z * p43.z;
  const d2121 = p21.x * p21.x + p21.y * p21.y + p21.z * p21.z;

  const numer = d1343 * d4321 - d1321 * d4343;
  const denom = d2121 * d4343 - d4321 * d4321;

  const mua = numer / denom;
  const mub = (d1343 + d4321 * mua) / d4343;

  if (Math.abs(denom) < EPSILON) {
    return false;
  }

  pa.addVectors(p1, p21.multiplyScalar(mua));
  pb.addVectors(p3, p43.multiplyScalar(mub));

  return true;
};

const getDefaultPosition = cameraExtrinsics => {
  const cameraMatrixA = createModelViewMatrix(cameraExtrinsics);
  const cameraMatrixB = createModelViewMatrix(null);

  const positionA = new THREE.Vector3();
  const positionB = new THREE.Vector3();

  positionA.set(cameraMatrixA.elements[12], cameraMatrixA.elements[13], cameraMatrixA.elements[14]);
  positionB.set(cameraMatrixB.elements[12], cameraMatrixB.elements[13], cameraMatrixB.elements[14]);

  const directionA = new THREE.Vector3(0, 0, 1);
  const directionB = new THREE.Vector3(0, 0, 1);

  directionA.set(-cameraMatrixA.elements[8], -cameraMatrixA.elements[9], -cameraMatrixA.elements[10]);
  directionB.set(-cameraMatrixB.elements[8], -cameraMatrixB.elements[9], -cameraMatrixB.elements[10]);

  directionA.normalize();
  directionB.normalize();

  directionA.multiplyScalar(2000);
  directionB.multiplyScalar(2000);

  directionA.add(positionA);
  directionB.add(positionB);

  const segmentA = new THREE.Vector3();
  const segmentB = new THREE.Vector3();
  const midpoint = new THREE.Vector3();

  const result = lineLineIntersect(positionA, directionA, positionB, directionB, segmentA, segmentB);
  if (result) {
    midpoint.addVectors(segmentA, segmentB).multiplyScalar(0.5);
  }
  return midpoint;
};

const useCanvas = (options, onChange) => {
  const canvasRef = useRef(null);
  const { key } = options;
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('webgl2');

    const [width, height] = getSize(context, options);
    const {
      width: imageWidth,
      height: imageHeight,
      values: boxValues,
      cameraMatrix,
      cameraExtrinsics,
      defaultPosition,
    } = options;
    const { length: boxLength, width: boxWidth, height: boxHeight, x, y, z, roll, pitch, yaw } = boxValues;

    const modelViewMatrix = createModelViewMatrix(cameraExtrinsics);

    const parentEl = canvas.parentElement;
    parentEl.style.height = `${height}px`;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera();
    updateProjectionMatrix(camera, cameraMatrix, imageWidth, imageHeight);
    camera.applyMatrix4(modelViewMatrix);
    scene.add(camera);

    const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
    const boxEdges = new THREE.EdgesGeometry(boxGeometry);
    const boxLines = new THREE.LineSegments(boxEdges);
    boxLines.material.depthTest = false;
    boxLines.material.opacity = 0.5;
    boxLines.material.transparent = true;
    scene.add(boxLines);

    boxLines.scale.set(boxLength, boxWidth, boxHeight);

    const rotation = new THREE.Euler(pitch * DEGREES_TO_RADIANS, yaw * DEGREES_TO_RADIANS, roll * DEGREES_TO_RADIANS);
    boxLines.quaternion.setFromEuler(rotation);

    if (x === 0 && y === 0 && z === 0) {
      boxLines.position.set(defaultPosition.x, defaultPosition.y, defaultPosition.z);
    } else {
      boxLines.position.set(x, y, z);
    }

    const renderer = new THREE.WebGLRenderer({ canvas, alpha: true, antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    const control = new TransformControls(camera, renderer.domElement);
    control.size = 0.5;
    control.space = 'local';
    control.attach(boxLines);
    scene.add(control);

    const render = () => {
      renderer.render(scene, camera);
    };

    const handleResize = () => {
      const [width, height] = getSize(context, options);
      parentEl.style.height = `${height}px`;
      renderer.setSize(width, height);
      render();
    };

    const handleKeydown = e => {
      switch (e.keyCode) {
        case 81: // Q
          control.setSpace(control.space === 'local' ? 'world' : 'local');
          break;
        case 87: // W
          control.setMode('translate');
          break;
        case 69: // E
          control.setMode('rotate');
          break;
        case 82: // R
          control.setMode('scale');
          break;
        case 187:
        case 107: // +, =, num+
          control.setSize(control.size + 0.1);
          break;
        case 189:
        case 109: // -, _, num-
          control.setSize(Math.max(control.size - 0.1, 0.1));
          break;
        case 88: // X
          control.showX = !control.showX;
          break;
        case 89: // Y
          control.showY = !control.showY;
          break;
        case 90: // Z
          control.showZ = !control.showZ;
          break;
        case 32: // Spacebar
          control.enabled = !control.enabled;
          break;
        case 27: // Esc
          control.reset();
          break;
        default:
          break;
      }
    };

    const handleChange = () => {
      onChange({
        length: boxLines.scale.x,
        width: boxLines.scale.y,
        height: boxLines.scale.z,
        x: boxLines.position.x,
        y: boxLines.position.y,
        z: boxLines.position.z,
        pitch: boxLines.rotation.x * RADIANS_TO_DEGREES,
        yaw: boxLines.rotation.y * RADIANS_TO_DEGREES,
        roll: boxLines.rotation.z * RADIANS_TO_DEGREES,
      });
      render();
    };

    handleChange();

    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', handleKeydown);
    control.addEventListener('change', handleChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handleKeydown);
      control.removeEventListener('change', handleChange);
    };
  }, [key]); // eslint-disable-line
  return canvasRef;
};

export const BoundingBox = ({ src, onChange, options }) => {
  const { values, product, cameraId } = options;
  const { cameraExtrinsics: extrinsics, cameraIntrinsics: intrinsics } = product;
  const cameraMatrix = intrinsics?.[cameraId]?.['matrix'];

  const cameraPair = Object.keys(extrinsics).find(cameraPair => {
    const cameraA = cameraPair.split('_')[0];
    return cameraId === cameraA;
  });
  const cameraExtrinsics = extrinsics[cameraPair];

  const defaultPosition = getDefaultPosition(Object.values(extrinsics)[0]);

  const defaults = {
    key: cameraId,
    cameraMatrix,
    cameraExtrinsics,
    defaultPosition,
    values,
    width: 1440,
    height: 1080,
  };

  const canvasOptions = Object.assign(defaults, options);
  const canvasRef = useCanvas(canvasOptions, onChange);

  return (
    <div className="w-full bg-contain bg-no-repeat" style={{ backgroundImage: `url(${src})` }}>
      <canvas ref={canvasRef} width={canvasOptions.width} height={canvasOptions.height} tabIndex={-1} />
    </div>
  );
};

export default BoundingBox;
