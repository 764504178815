import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { API } from 'aws-amplify';

const FILTERS = ['GOOD', 'BAD', 'IGNORE'];

export const getFrames = createAsyncThunk('frames/getFrames', async payload => {
  const { jobId, query } = payload;
  if (!FILTERS.includes(query.quality)) delete query.quality;
  const queryStr = new URLSearchParams(query);
  try {
    return await API.get('api', `/jobs/${jobId}/frames?${queryStr}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const getFrame = createAsyncThunk('frames/getFrame', async payload => {
  const { jobId, frameId } = payload;
  try {
    return await API.get('api', `/jobs/${jobId}/frames/${frameId}`);
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const updateFrame = createAsyncThunk('frames/updateFrame', async payload => {
  const { jobId, frameId, labeledQuality, tags } = payload;
  try {
    return await API.put('api', `/jobs/${jobId}/frames/${frameId}`, { body: { labeledQuality, tags } });
  } catch (err) {
    throw new Error(err.response.data);
  }
});

export const framesSlice = createSlice({
  name: 'frames',
  initialState: {
    items: {},
    next: {},
  },
  reducers: {
    'updateFrame/pending': (state, action) => {
      const { jobId, frameId, ...rest } = action.meta.arg;
      const frame = state.items?.[jobId]?.[frameId] || {};
      const updates = Object.entries(rest);

      updates.forEach(([key, value]) => {
        frame[key] = value;
      });

      state.items[jobId][frameId] = frame;
    },
    'getFrames/fulfilled': (state, action) => {
      const { jobId, frames, next } = action.payload;
      const jobFrames = state.items[jobId] ?? {};

      frames.forEach(newFrame => {
        const { frameId } = newFrame;
        const oldFrame = jobFrames[frameId] ?? {};
        const updatedFrame = Object.assign(oldFrame, newFrame);
        jobFrames[frameId] = updatedFrame;
      });
      state.items[jobId] = jobFrames;

      if (next) {
        state.next[jobId] = next;
      } else {
        delete state.next[jobId];
      }
    },
    'getFrame/fulfilled': (state, action) => {
      const frame = action.payload;
      const { jobId, frameId } = frame;

      if (!(jobId in state.items)) {
        state.items[jobId] = {};
      }
      state.items[jobId][frameId] = frame;
    },
  },
});

const selectItems = (state, props) => state.frames.items[props.jobId];
const selectItem = (state, props) => props.frameId;
const selectFilter = (state, props) => props.filter;
const selectPage = (state, props) => props.page;

export const selectFrame = () => {
  return createSelector([selectItems, selectItem], (data, id) => {
    const items = data || {};
    return items[id] || {};
  });
};

export const selectFrames = () => {
  return createSelector([selectItems, selectFilter, selectPage], (data, quality, page) => {
    const items = Object.values(data || {});

    const qualityFilter = quality ? quality.toUpperCase() : null;
    const filteredItems = FILTERS.includes(qualityFilter)
      ? items.filter(item => item.labeledQuality === qualityFilter)
      : items.filter(item => !item.labeledQuality);

    // pagination
    const total = filteredItems.length;
    const pageNumber = page ?? 1;
    const itemsPerPage = page ? 120 : total;
    const previousPage = pageNumber - 1;
    const pages = Math.ceil(total / itemsPerPage);

    const results = filteredItems.slice(previousPage * itemsPerPage, pageNumber * itemsPerPage);
    return {
      items: results,
      total,
      pages,
      pageNumber,
      itemsPerPage,
    };
  });
};

export default framesSlice.reducer;
