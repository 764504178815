import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { Auth } from 'aws-amplify';
import config from 'config.json';

import WebRTCViewer from 'utils/WebRTCViewer';

export const startViewer = createAsyncThunk('streams/startViewer', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { cameraId, deviceId } = payload;
  const channelName = `${deviceId}-camera${cameraId}`;

  const currentCredentials = await Auth.currentCredentials();
  const credentials = Auth.essentialCredentials(currentCredentials);

  try {
    const viewer = new WebRTCViewer(credentials, channelName, config.AWSRegion, false);
    await viewer.init();

    viewer.signalingClient.on('open', () => {
      dispatch({
        type: 'streams/onOpen',
        payload: {
          cameraId,
          deviceId,
          clientId: viewer.clientId,
        },
      });
    });
    viewer.signalingClient.on('close', () => {
      dispatch({
        type: 'streams/onClose',
        payload: {
          cameraId,
          deviceId,
          clientId: viewer.clientId,
        },
      });
    });
    viewer.signalingClient.on('error', error => {
      dispatch({
        type: 'streams/onError',
        payload: {
          cameraId,
          deviceId,
          clientId: viewer.clientId,
        },
      });
    });

    viewer.peerConnection.addEventListener('track', event => {
      dispatch({
        type: 'streams/onTrack',
        payload: {
          cameraId,
          deviceId,
          clientId: viewer.clientId,
        },
      });
    });

    WebRTCViewer.viewers[channelName] = viewer;
    viewer.open();
  } catch (err) {
    console.error(err);
    throw err;
  }
});

export const stopViewer = createAsyncThunk('streams/stopViewer', async (payload, thunkAPI) => {
  const { deviceId, cameraId } = payload;
  const channelName = `${deviceId}-camera${cameraId}`;

  const viewer = WebRTCViewer.viewers[channelName];
  viewer.close();

  WebRTCViewer.viewers[channelName] = null;
  delete WebRTCViewer.viewers[channelName];
});

export const streamsSlice = createSlice({
  name: 'streams',
  initialState: {
    error: '',
    items: {},
  },
  reducers: {
    'startViewer/pending': (state, action) => {
      const { deviceId, cameraId } = action.meta.arg;
      const device = state.items[deviceId] || {};
      device[cameraId] = {
        error: null,
        status: 'CONNECTING',
      };

      state.items[deviceId] = device;
    },
    'startViewer/fulfilled': (state, action) => {
      const { deviceId, cameraId } = action.meta.arg;
      const device = state.items[deviceId] || {};
      device[cameraId] = {
        error: null,
        status: 'CONNECTED',
      };

      state.items[deviceId] = device;
    },
    'startViewer/rejected': (state, action) => {
      const { error } = action;

      const { deviceId, cameraId } = action.meta.arg;
      const device = state.items[deviceId] || {};
      device[cameraId] = {
        error: error.message,
        status: 'ERROR',
      };

      state.items[deviceId] = device;
    },
    onOpen: (state, action) => {
      const { deviceId, cameraId, clientId } = action.payload;
      const device = state.items[deviceId] || {};
      device[cameraId]['client'] = {
        clientId,
        status: 'OPENED',
      };

      state.items[deviceId] = device;
    },
    onClose: (state, action) => {
      const { deviceId, cameraId, clientId } = action.payload;
      const device = state.items[deviceId] || {};
      device[cameraId]['client'] = {
        clientId,
        status: 'CLOSED',
      };

      state.items[deviceId] = device;
    },
    onError: (state, action) => {
      const { deviceId, cameraId, clientId } = action.payload;
      const device = state.items[deviceId] || {};
      device[cameraId]['client'] = {
        clientId,
        status: 'ERROR',
      };

      state.items[deviceId] = device;
    },
    onTrack: (state, action) => {
      const { deviceId, cameraId, clientId } = action.payload;
      const device = state.items[deviceId] || {};
      device[cameraId]['client'] = {
        clientId,
        status: 'TRACK',
      };

      state.items[deviceId] = device;
    },
  },
  extraReducers: {},
});

const selectItems = (state, props) => state.streams.items[props.deviceId];
const selectItem = (state, props) => props.cameraId;

export const selectStream = () => {
  return createSelector([selectItems, selectItem], (data, id) => {
    const items = data || {};
    return items[id] || {};
  });
};

export const selectTotalStreams = () => {
  return createSelector([selectItems], data => {
    return Object.values(data || {}).length;
  });
};

export const selectStreams = () => {
  return createSelector([selectItems], data => {
    return Object.values(data || {});
  });
};

export default streamsSlice.reducer;
