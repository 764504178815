import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { XCircleIcon } from '@heroicons/react/24/outline';

import { updateJob } from 'state/slices/jobsSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningDialog from '../components/WarningDialog';
import WarningBanner from '../components/WarningBanner';
import Tabs from '../components/Tabs';

import getJobSettingsNav from '../navs/jobSettingsNav';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

export const Tags = props => {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const { headerTitle, sectionNav, job, sectionTitle } = props;
  const { message, status, method } = useSelector(state => state.status);
  const [showWarning, setShowWarning] = useState(false);
  const [deletedTag, setDeletedTag] = useState();

  const formik = useFormik({
    initialValues: {
      newTag: '',
    },
    validate: values => {
      const errors = {};
      if (!values.newTag) {
        errors.newTag = 'Required';
      }
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      const { newTag } = values;
      const { jobId, tags: currentTags = [] } = job;
      const tags = [...currentTags, newTag];
      dispatch(updateJob({ jobId, tags }));
      resetForm();
    },
  });

  const deleteTagHandler = () => {
    const { jobId, tags: currentTags } = job;
    const tags = currentTags.filter(tag => tag !== deletedTag);
    dispatch(updateJob({ jobId, tags }));
    setShowWarning(false);
  };

  const warningHandler = tag => {
    setDeletedTag(tag);
    setShowWarning(true);
  };

  return (
    <PrivateTemplate
      sectionNav={sectionNav}
      sectionTitle={sectionTitle}
      headerTitle={`${headerTitle} › Label Settings`}>
      <Tabs nav={getJobSettingsNav(jobId, 'settings-labels', job)} />
      {method === 'updateJob' && status === 'rejected' && <WarningBanner text={message} />}
      <WarningDialog
        title={`Final check: ${deletedTag}`}
        text={`Deleting this tag cannot be undone. Removing this tag removes all realted labels and data from your system.`}
        confirmHandler={deleteTagHandler}
        cancelHandler={() => setShowWarning(false)}
        isOpen={showWarning}
      />

      <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="overflow-scroll border-b border-gray-200">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tag
                  </th>

                  <th scope="col" className="relative px-2 lg:px-6 py-3">
                    <span className="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {job?.tags?.map(tag => (
                  <tr key={tag}>
                    <td className="px-6 lg:px-8 py-4 whitespace-nowrap">{tag}</td>

                    <td className="px-2 lg:px-6 py-4 whitespace-nowrap align-middle text-right text-sm font-medium">
                      <button disabled={status === 'pending'} type="submit" onClick={() => warningHandler(tag)}>
                        <XCircleIcon
                          className="h-8 w-8 inline-flex align-middle text-gray-400 hover:text-red-600"
                          aria-hidden="true"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <form onSubmit={formik.handleSubmit}>
              <table className="w-full border-y border-gray-200 divide-y divide-gray-200">
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                      {' '}
                      {formik.errors.newTag && formik.touched.newTag && <Error>{formik.errors.newTag}</Error>}
                      <input
                        type="text"
                        name="newTag"
                        id="newTag"
                        placeholder="Tag name"
                        onChange={formik.handleChange}
                        value={formik.values.newTag}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-gray-900 sm:text-sm focus:ring-indigo-700 focus:border-indigo-500"
                      />
                    </td>
                    <td className="px-2 lg:px-6 py-4 whitespace-nowrap align-middle text-right text-sm font-medium">
                      <button
                        type="submit"
                        disabled={status === 'pending'}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                        Add Tag
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Tags;
