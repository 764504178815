import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import { inviteUser } from 'state/slices/usersSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

const validate = values => {
  const errors = {};
  if (!values.givenName) {
    errors.givenName = 'Required';
  }
  if (!values.familyName) {
    errors.familyName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

export const InviteUser = () => {
  const dispatch = useDispatch();
  const { message, status, payload, method } = useSelector(state => state.status);

  const formik = useFormik({
    initialValues: {
      givenName: '',
      familyName: '',
      email: '',
      password: 'ChangeMe!',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      dispatch(inviteUser(values));
      resetForm();
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <PrivateTemplate headerTitle="Account › Invite User">
      {method === 'inviteUser' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully invited ${payload.email}`} />
      )}
      {status === 'rejected' && <WarningBanner text={message} />}

      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label htmlFor="givenName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    First name
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.givenName && formik.touched.givenName && <Error>{formik.errors.givenName}</Error>}
                    <input
                      type="text"
                      name="givenName"
                      id="givenName"
                      autoComplete="given-name"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.givenName}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="familyName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Last Name
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.familyName && formik.touched.familyName && <Error>{formik.errors.familyName}</Error>}
                    <input
                      type="text"
                      name="familyName"
                      id="familyName"
                      autoComplete="family-name"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.familyName}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Email
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.email && formik.touched.email && <Error>{formik.errors.email}</Error>}
                    <input
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Temporary Password
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.password && formik.touched.password && <Error>{formik.errors.password}</Error>}
                    <input
                      type="text"
                      name="password"
                      id="password"
                      autoComplete="password"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <Link
                to="/account/users"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
              >
                Cancel
              </Link>
              <button
                type="submit"
                disabled={status === 'pending'}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
              >
                Invite
              </button>
            </div>
          </div>
        </form>
      </div>
    </PrivateTemplate>
  );
};

export default InviteUser;
