import React from 'react';
import { useSelector } from 'react-redux';

import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import FlashMessage from './components/FlashMessage';

export const App = () => {
  const authorizedUser = useSelector(state => state.auth.user);
  return (
    <div className="app">
      <FlashMessage />
      {authorizedUser ? <PrivateRoutes /> : <PublicRoutes />}
    </div>
  );
};

export default App;
