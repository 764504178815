import {
  CubeIcon,
  ArrowPathIcon,
  RectangleStackIcon,
  TagIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';

export const getJobNav = (jobId, section, job) => {
  const currentSection = section || 'products';
  const { targetMeasurements } = job;
  const nav = [];

  nav.push({
    name: 'Products',
    to: `/job/${jobId}/products`,
    icon: RectangleStackIcon,
    current: currentSection.startsWith('product'),
  });
  nav.push({
    name: 'Labels',
    to: `/job/${jobId}/labels`,
    icon: TagIcon,
    current: currentSection.startsWith('label'),
  });
  if (targetMeasurements) {
    nav.push({
      name: 'Sizes',
      to: `/job/${jobId}/sizes`,
      icon: CubeIcon,
      current: currentSection.startsWith('size'),
    });
  }
  nav.push({
    name: 'Models',
    to: `/job/${jobId}/models`,
    icon: ArrowPathIcon,
    current: currentSection.startsWith('model'),
  });
  nav.push({
    name: 'Settings',
    to: `/job/${jobId}/settings`,
    icon: WrenchScrewdriverIcon,
    current: ['settings', 'setup', 'rois', 'configure'].includes(currentSection),
  });
  return nav;
};

export default getJobNav;
