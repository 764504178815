import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay, dependencies = []) {
  const savedCallback = useRef();
  const savedTimeout = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback, ...dependencies]); // eslint-disable-line

  useEffect(() => {
    function tick() {
      savedCallback.current();
      savedTimeout.current = setTimeout(tick, delay);
    }
    if (delay !== null) {
      savedTimeout.current = setTimeout(tick, delay);
      return () => {
        clearTimeout(savedTimeout.current);
      };
    }
  }, [callback, delay, ...dependencies]); // eslint-disable-line
}
