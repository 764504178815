import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { getUser, updateUser } from 'state/slices/usersSlice';

import getAccountNav from '../navs/accountNav';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
}; //

const validate = values => {
  const errors = {};
  if (!values.givenName) {
    errors.givenName = 'Required';
  }
  if (!values.familyName) {
    errors.familyName = 'Required';
  }
  return errors;
};

export const UpdateUser = () => {
  const dispatch = useDispatch();
  const sectionNav = getAccountNav('user');
  const { message, method, status } = useSelector(state => state.status);

  const authUser = useSelector(state => state.auth.user);
  const userId = authUser.sub;

  const userData = useSelector(state => state.users.items[userId]) || {};
  const user = Object.assign(
    {
      familyName: '',
      givenName: '',
      phone: '',
      title: '',
    },
    userData
  );

  useEffect(() => {
    dispatch(getUser({ userId }));
  }, [userId]); // eslint-disable-line

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId,
      givenName: user.givenName,
      familyName: user.familyName,
      phone: user.phone,
      title: user.title,
    },
    validate,
    onSubmit: values => {
      dispatch(updateUser(values));
    },
  });

  const handleSubmit = e => {
    formik.handleSubmit();
    e.preventDefault();
  };

  return (
    <PrivateTemplate headerTitle="Account › Profile" sectionTitle="Manage Account" sectionNav={sectionNav}>
      {method === 'updateUser' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully updated ${formik.values.givenName}`} />
      )}
      {method === 'updateUser' && status === 'rejected' && <WarningBanner text={message} />}

      <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:px-8">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="space-y-6 sm:space-y-4">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                  <label htmlFor="givenName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    First Name*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.givenName && formik.touched.givenName && <Error>{formik.errors.givenName}</Error>}
                    <input
                      type="text"
                      name="givenName"
                      id="givenName"
                      onChange={formik.handleChange}
                      value={formik.values.givenName}
                      autoComplete="given-name"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="familyName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Last Name*
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.familyName && formik.touched.familyName && <Error>{formik.errors.familyName}</Error>}
                    <input
                      type="text"
                      name="familyName"
                      id="familyName"
                      autoComplete="family-name"
                      onChange={formik.handleChange}
                      value={formik.values.familyName}
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Phone
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.phone && formik.touched.phone && <Error>{formik.errors.phone}</Error>}
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4">
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Title
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.title && formik.touched.title && <Error>{formik.errors.title}</Error>}
                    <input
                      type="text"
                      name="title"
                      id="title"
                      autoComplete="organization-title"
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-700 focus:border-red-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </PrivateTemplate>
  );
};

export default UpdateUser;
