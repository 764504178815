import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getModel, selectModel, activateModel } from 'state/slices/modelsSlice';

import { CubeIcon } from '@heroicons/react/24/outline';

import PrivateTemplate from '../components/PrivateTemplate';
import EmptyState from '../components/EmptyState';
import MatrixChart from '../components/MatrixChart';

import formatTimestamp from 'utils/formatTimestamp';

export const Model = props => {
  const dispatch = useDispatch();
  const { sectionNav, job, headerTitle } = props;

  const { jobId, paramA: modelId } = useParams();

  const getSelectedModel = useMemo(selectModel, []);
  const model = useSelector(state => getSelectedModel(state, { jobId, modelId }));
  const { metrics = {}, status, createdAt } = model;
  const isActiveModel = modelId === job?.activeModelId;

  useEffect(() => {
    dispatch(getModel({ jobId, modelId }));
  }, [jobId, modelId]); // eslint-disable-line

  const activateModelHandler = modelId => {
    dispatch(activateModel({ jobId, modelId }));
  };

  const headerActions =
    !isActiveModel && status === 'Completed' ? (
      <button
        onClick={() => activateModelHandler(modelId)}
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <CubeIcon className="-ml-1 mr-3 h-5 w-5" /> Deploy
      </button>
    ) : null;

  const qualityScoreData = [
    { x: 'Positives', y: 'Positives', value: metrics?.true_positives_quality, key: 'True Positives' },
    { x: 'Negatives', y: 'Positives', value: metrics?.false_positives_quality, key: 'False Positives' },
    { x: 'Positives', y: 'Negatives', value: metrics?.false_negatives_quality, key: 'False Negatives' },
    { x: 'Negatives', y: 'Negatives', value: metrics?.true_negatives_quality, key: 'True Negatives' },
  ];

  let emptyState = null;
  if (status === 'InProgress') {
    emptyState = <EmptyState title="In Progress" description="Please wait for training complete." />;
  } else if (status === 'Failed') {
    emptyState = <EmptyState title="Failed" description="Model failed to train." />;
  } else if (!metrics || metrics.length === 0) {
    emptyState = <EmptyState title="Metric Unavailable" description="No metrics available." />;
  }

  const modelName = createdAt ? formatTimestamp(new Date(createdAt)) : modelId;
  return (
    <PrivateTemplate
      headerTitle={`${headerTitle} › Models › ${modelName}`}
      headerActions={headerActions}
      sectionNav={sectionNav}
      sectionTitle="Manage Job"
    >
      {emptyState || (
        <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200 border-b border-gray-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Label Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Count
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium ">Good</div>
                    </td>
                    <td className="px-6 py-4  whitespace-nowrap">
                      <div className="text-sm font-medium ">{metrics.labels_good}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium ">Bad</div>
                    </td>
                    <td className="px-6 py-4  whitespace-nowrap">
                      <div className="text-sm font-medium ">{metrics.labels_bad}</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium ">Measurements</div>
                    </td>
                    <td className="px-6 py-4  whitespace-nowrap">
                      <div className="text-sm font-medium ">{metrics.measurements}</div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="min-w-full divide-y divide-gray-200">
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium ">Quality Score (Uncertainty)</div>
                    </td>
                    <td className="px-6 py-4  whitespace-nowrap">
                      <div className="text-sm font-medium ">
                        {metrics?.score_quality?.toFixed(2)} ({metrics?.uncertainty_quality?.toFixed(2)})
                      </div>
                    </td>
                    <td className="px-6 py-4  whitespace-nowrap max-w-xs">
                      <div className="text-sm font-medium ">
                        {metrics && <MatrixChart labels={['Positives', 'Negatives']} data={qualityScoreData} />}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </PrivateTemplate>
  );
};

export default Model;
