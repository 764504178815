import React, { useRef, useEffect } from 'react';

const getSizeAndScale = (context, options) => {
  const canvas = context.canvas;

  const visualViewport = window.visualViewport;
  const parentEl = canvas.parentElement;
  const parentSize = parentEl.getBoundingClientRect();

  const parentWidth = parentSize.width;
  const parentHeight = visualViewport.height - parentSize.top;
  const parentAspectRatio = parentWidth / parentHeight;

  const { width: optionsWidth, height: optionsHeight } = options;
  const optionsAspectRatio = optionsWidth / optionsHeight;

  let width, height;
  if (optionsAspectRatio < parentAspectRatio) {
    width = (optionsWidth * parentHeight) / optionsHeight;
    height = parentHeight;
  } else {
    height = (optionsHeight * parentWidth) / optionsWidth;
    width = parentWidth;
  }

  return {
    size: { width, height },
    scale: {
      x: width / options.width,
      y: height / options.height,
    },
  };
};

const resizeCanvas = (context, options) => {
  const canvas = context.canvas;
  const parentEl = canvas.parentElement;

  const { devicePixelRatio = 1 } = window;
  const { width, height } = options;

  canvas.width = width * devicePixelRatio;
  canvas.height = height * devicePixelRatio;

  context.scale(devicePixelRatio, devicePixelRatio);

  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
  parentEl.style.height = `${height}px`;
};

const useCanvas = (draw, options = {}, events) => {
  const canvasRef = useRef(null);
  const requestIdRef = useRef(null);
  const { key } = options;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext(options.context || '2d');

    const handleResize = () => {
      delete options.scale;
      if (!options.animate) {
        requestIdRef.current = window.requestAnimationFrame(handleDraw);
      }
    };

    const handleDraw = time => {
      if (!options.scale) {
        const { size, scale } = getSizeAndScale(context, options);
        resizeCanvas(context, size);
        options.scale = scale;
      }
      if (options.animate) {
        requestIdRef.current = window.requestAnimationFrame(handleDraw);
      }
      draw(context, options, time);
    };

    requestIdRef.current = window.requestAnimationFrame(handleDraw);
    window.addEventListener('resize', handleResize);

    // add optional extra event handlers
    if (events) {
      events.forEach(([event, handler]) => {
        const targetEl = event === 'mouseup' ? window : canvas;
        targetEl.addEventListener(event, handler.bind(this, context, options));
      });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (requestIdRef.current) {
        window.cancelAnimationFrame(requestIdRef.current);
      }
      if (events) {
        events.forEach(([event, handler]) => {
          const targetEl = event === 'mouseup' ? window : canvas;
          targetEl.removeEventListener(event, handler);
        });
      }
    };
  }, [key]); // eslint-disable-line
  return canvasRef;
};

const Canvas = props => {
  const { draw, options, events, ...rest } = props;
  const defaults = {
    width: 1440,
    height: 1080,
  };

  const canvasOptions = Object.assign(defaults, options);
  const canvasRef = useCanvas(draw, canvasOptions, events);
  return <canvas ref={canvasRef} width={canvasOptions.width} height={canvasOptions.height} {...rest} />;
};

export default Canvas;
