import { CameraIcon } from '@heroicons/react/24/outline';

export const getCameraNav = (type, id, section, cameraIds, cameraId) => {
  const activeCameraId = cameraId || cameraIds[0];
  return cameraIds.map(cameraId => {
    return {
      name: cameraId,
      to: `/${type}/${id}/${section}/${cameraId}`,
      icon: CameraIcon,
      current: activeCameraId === cameraId,
    };
  });
};

export default getCameraNav;
