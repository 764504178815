import { FaceSmileIcon, FaceFrownIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export const getMeasurementsNav = (jobId, label) => {
  const currentLabel = label || 'review';
  return [
    {
      name: 'Review',
      to: `/job/${jobId}/sizes/1/review`,
      icon: QuestionMarkCircleIcon,
      css: 'text-yellow-500',
      current: currentLabel === 'review',
    },
    {
      name: 'Good',
      to: `/job/${jobId}/sizes/1/good`,
      icon: FaceSmileIcon,
      css: 'text-green-500',
      current: currentLabel === 'good',
    },
    {
      name: 'Bad',
      to: `/job/${jobId}/sizes/1/bad`,
      icon: FaceFrownIcon,
      css: 'text-red-500',
      current: currentLabel === 'bad',
    },
  ];
};

export default getMeasurementsNav;
