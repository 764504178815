import { FaceSmileIcon, FaceFrownIcon, RectangleStackIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export const getProductNav = (jobId, quality) => {
  const currentQuality = quality || 'recent';
  return [
    {
      name: 'Recent',
      to: `/job/${jobId}/products/1/recent`,
      icon: RectangleStackIcon,
      css: 'text-indigo-500',
      current: currentQuality === 'recent',
    },
    {
      name: 'Good',
      to: `/job/${jobId}/products/1/good`,
      icon: FaceSmileIcon,
      css: 'text-green-500',
      current: currentQuality === 'good',
    },

    {
      name: 'Bad',
      to: `/job/${jobId}/products/1/bad`,
      icon: FaceFrownIcon,
      css: 'text-red-500',
      current: currentQuality === 'bad',
    },
    {
      name: 'Uncertain',
      to: `/job/${jobId}/products/1/uncertain`,
      icon: QuestionMarkCircleIcon,
      css: 'text-yellow-500',
      current: currentQuality === 'uncertain',
    },
  ];
};

export default getProductNav;
