import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

import { registerDevice } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import WarningBanner from '../components/WarningBanner';
import SuccessBanner from '../components/SuccessBanner';

const Error = props => {
  return <span className="text-red-500 font-extrabold text-xs">{props.children}</span>;
};

const validate = values => {
  const errors = {};
  if (!values.deviceId) {
    errors.deviceId = 'Required';
  }
  if (!values.deviceName) {
    errors.deviceName = 'Required';
  }
  return errors;
};

export const AddDevice = () => {
  const { message, status, args, method } = useSelector(state => state.status);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      deviceId: '',
      deviceName: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const { deviceId, deviceName } = values;
      dispatch(registerDevice({ deviceId, deviceName }));
      resetForm();
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <PrivateTemplate headerTitle="Add Station">
      {method === 'registerDevice' && status === 'fulfilled' && (
        <SuccessBanner text={`Successfully registered ${args.deviceName}`} to={`/station/${args.deviceId}/jobs`} />
      )}
      {status === 'rejected' && <WarningBanner text={message} />}

      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label htmlFor="deviceId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Station Id
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.deviceId && formik.touched.deviceId && <Error>{formik.errors.deviceId}</Error>}
                    <input
                      id="deviceId"
                      name="deviceId"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.deviceId}
                      placeholder="station id"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="deviceName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Station Name
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    {formik.errors.deviceName && formik.touched.deviceName && <Error>{formik.errors.deviceName}</Error>}
                    <input
                      id="deviceName"
                      name="deviceName"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.deviceName}
                      placeholder="station name"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-700 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <Link
                to="/stations"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
              >
                Cancel
              </Link>
              <button
                type="submit"
                disabled={status === 'pending'}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-800 hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </PrivateTemplate>
  );
};

export default AddDevice;
