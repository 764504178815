export const linkItems = (itemId, items) => {
  return items.reduce((results, item, index, items) => {
    const key = item[itemId];
    const previous = items[index - 1] ?? {};
    const next = items[index + 1] ?? {};
    results[key] = Object.assign(
      {
        index: index + 1,
        next: next?.[itemId],
        previous: previous?.[itemId],
      },
      item
    );
    return results;
  }, {});
};

export default linkItems;
