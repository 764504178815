import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Switch } from '@headlessui/react';

import {
  MagnifyingGlassIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  XCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';

import { getJobs, selectJobs, selectJob, deleteJob } from 'state/slices/jobsSlice';
import { activateDeviceJob, updateComponentState } from 'state/slices/devicesSlice';

import PrivateTemplate from '../components/PrivateTemplate';
import Pagination from '../components/Pagination';
import EmptyState from '../components/EmptyState';
import WarningDialog from '../components/WarningDialog';

import classNames from 'utils/classNames';

export const Jobs = () => {
  const { filter, pageNumber } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showWarning, setShowWarning] = useState(false);
  const [jobId, setJobId] = useState('');

  const page = parseInt(pageNumber) || 1;
  const getSelectedJobs = useMemo(selectJobs, []);
  const pageData = useSelector(state => getSelectedJobs(state, { filter, page }));
  const { status } = useSelector(state => state.status);

  const getSelectedJob = useMemo(selectJob, []);
  const { jobName } = useSelector(state => getSelectedJob(state, { jobId }));

  useEffect(() => {
    dispatch(getJobs());
  }, []); // eslint-disable-line

  const filterHandler = e => {
    const filter = encodeURIComponent(e.target.value);
    history.push(`/jobs/${page}/${filter}`);
  };

  const deleteJobHandler = () => {
    dispatch(deleteJob({ jobId }));
    setShowWarning(false);
  };

  const activateJobHandler = (job, isActive) => {
    const deviceId = job.device.deviceId;
    const jobId = job.jobId;

    if (!isActive) {
      dispatch(activateDeviceJob({ deviceId, jobId, mode: 'ACTIVE' }));
    } else {
      dispatch(
        updateComponentState({
          deviceId,
          componentName: 'QualityControl',
          state: { mode: 'READY' },
        })
      );
    }
  };

  const warningHandler = id => {
    setJobId(id);
    setShowWarning(true);
  };

  const headerActions = (
    <div className="sm:flex-1 sm:flex items-center justify-between">
      <WarningDialog
        title={`Final check: ${jobName}`}
        text={`Deleting cannot be undone. Removing this job removes all realted training labels and data from your system.`}
        confirmHandler={deleteJobHandler}
        cancelHandler={() => setShowWarning(false)}
        isOpen={showWarning}
      />
      <Link to="/jobs/add" className="mr-8 flex-1 flex items-center ">
        <PlusCircleIcon className="mr-2 h-5 w-5 hover:text-indigo-900 text-indigo-400" aria-hidden="true" />
        <span className="text-gray-900 hover:underline">Add Job</span>
      </Link>

      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name="search"
          className="focus:ring-indigo-700 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          placeholder="Search"
          defaultValue={filter}
          onChange={filterHandler}
        />
      </div>
    </div>
  );

  return (
    <PrivateTemplate headerTitle="Jobs" headerActions={headerActions}>
      <div className="flex flex-col flex-1 max-h-screen xl:overflow-y-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          {pageData.items.length === 0 ? (
            <EmptyState title="No Jobs" description="Get started by creating a new job." cta="Add Job" to="/jobs/add" />
          ) : (
            <div className="overflow-scroll border-b border-gray-200">
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 lg:px-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Job
                    </th>
                    <th
                      scope="col"
                      className="px-2 lg:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Station
                    </th>
                    <th scope="col" className="relative px-2 lg:px-6 py-3">
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {pageData.items.map(job => (
                    <tr key={job.jobId}>
                      <td className="px-6 lg:px-8 py-4 whitespace-nowrap">
                        <Switch.Group as="div" className="flex items-center justify-between">
                          <Switch
                            checked={job.device.activeJobId === job.jobId}
                            onChange={() => activateJobHandler(job, job.device.activeJobId === job.jobId)}
                            className={classNames(
                              job.device.activeJobId === job.jobId ? 'bg-indigo-800' : 'bg-gray-200',
                              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700'
                            )}>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                job.device.activeJobId === job.jobId ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                              )}
                            />
                          </Switch>
                        </Switch.Group>
                      </td>

                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Link to={`/job/${job.jobId}/labels`}>
                            <div className="flex-shrink-0 h-8 w-8">
                              {job.device.activeJobId === job.jobId ? (
                                <ClipboardDocumentCheckIcon className="h-6 w-6 text-gray-400" />
                              ) : (
                                <ClipboardDocumentIcon className="h-6 w-6 text-gray-400" />
                              )}
                            </div>
                          </Link>
                          <Link to={`/job/${job.jobId}`}>
                            <div className="ml-2 lg:ml-6">
                              <div className="text-sm font-medium ">{job.jobName}</div>
                              <div className="text-sm text-gray-500">{job.jobId}</div>
                            </div>
                          </Link>
                        </div>
                      </td>
                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap">
                        <Link to={`/job/${job.jobId}`}>
                          <div className="text-sm">{job.device.deviceName}</div>
                          <div className="text-sm text-gray-500">{job.device.deviceId}</div>
                        </Link>
                      </td>

                      <td className="px-2 lg:px-6 py-4 whitespace-nowrap align-middle text-right text-sm font-medium">
                        <button disabled={status === 'pending'} type="submit" onClick={() => warningHandler(job.jobId)}>
                          <XCircleIcon
                            className="h-8 w-8 inline-flex align-middle text-gray-400 hover:text-red-600"
                            aria-hidden="true"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination pageData={pageData} baseUrl={`/jobs/${filter}`} />
            </div>
          )}
        </div>
      </div>
    </PrivateTemplate>
  );
};

export default Jobs;
